import React from 'react';
import { useTranslation } from 'react-i18next';
import TermsText from './termsText';

const TermsNConditions = ()=> {

  const { t, i18n } = useTranslation('common');
    
  return(
    <div className="body">
      <h1 style={{color: '#333', letterSpacing: 2, paddingTop: '15px'}}>{t("common:terms-use")}</h1>
      <div className="categoryView">
        <TermsText />
      </div> 
    </div>
  )
}

export default TermsNConditions;