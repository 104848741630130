import React from 'react'
import ShoppingCart from '@material-ui/icons/ShoppingCart'

const AddToCartSmall = ({ onClick })=> {

    return(
        <button type="button" className="shoppingCart-button" onClick={onClick}>
            <ShoppingCart style={{color: '#89AAA9'}}/>
        </button>
    )
}

export default AddToCartSmall;