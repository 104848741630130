import React, { useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import {Alert} from 'react-bootstrap';

const SmallAlert = ({ show, styling, heading, onClose, message}) =>{

    
  return(
    <>
    <div className="cart-updated-alert">
      <CSSTransition
        in={show}
        timeout={300}
        classNames="my-alert"
        unmountOnExit
      >
        <Alert variant={styling}
        dismissible
        onClose={onClose}>
            <Alert.Heading>{heading}</Alert.Heading> 
            <p>{message}</p>
        </Alert>
      </CSSTransition>
    </div>
  </>
  )
}

export default SmallAlert;