import React, {useEffect} from 'react'
//import BagIcon from '@material-ui/icons/LocalMall'
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import { Link } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import "./CartButton.css"


const CartButton = ({ feathersStore }) => {

  useEffect(() => {
    const cart = localStorage.getItem('fire_cart');
    cart && feathersStore
      .setCartLength(JSON.parse(localStorage.getItem('fire_cart')).length);
  }, []);

  return (
    <>
      <Link to="/cart" >
        <button type="button" className="cart-button" style={{border: "none", outline: "none"}}>
          <ShoppingCartOutlinedIcon style={{color: "#fff"}}/>
          <span className="badge">{feathersStore.cartLength}</span>
        </button>
      </Link>    
    </>
  )
}


export default inject('feathersStore')(observer(CartButton));