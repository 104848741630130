import React, {useState, useEffect} from 'react';
import Modal from 'react-awesome-modal';
import { LoopCircleLoading } from 'react-loadingg';
import { inject, observer } from "mobx-react";
import { useTranslation } from 'react-i18next';

const LoadingOrderModal = ({visible, whenSent, whenError, feathersStore})=> {

  const { t, i18n } = useTranslation('common');
  
  useEffect(() => {
   visible && sendOrder();
  }, [visible]);

  const sendOrder = async() => {
    const orderCreated = await feathersStore.createOrder(feathersStore.orderItem);
    if(orderCreated){
      emptyCart(); 
      feathersStore.setCartLength(0);
      whenSent();
    }else{
      alert(
        `${t('common:connection-issue')}`
      )
      whenError();
    }
  }

  const emptyCart = () => {
    const emptyItem = {
      'customerId': "",
      'bookingDate': "",
      'status': '',
      'items': [],
      'paymentMethod':"",
      'total': 0,
      'processed': false,
      'latitude': 0,
      'longitude': 0,
      'city': "",
      'street': "",
      'streetNumber': "",
      'apartment': "",
      'postcode': "",
      'addressComments':""
    }
    localStorage.setItem("fire_cart", JSON.stringify([]));
    feathersStore.setOrderItem(emptyItem);
  }

  return (
    <section>
      <Modal visible={visible} width="300" height="270" effect="fadeInUp" >
        <div className="modalContainer">
          <h3>{t('common:send-order')}</h3>
          <a>{t('common:please-wait')}</a>
          <div className="modalRegisterFormContainer">
              <LoopCircleLoading />
          </div>
        </div>
      </Modal>
    </section>
  );
}

export default inject('feathersStore')(observer(LoadingOrderModal));
