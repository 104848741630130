import React from 'react'
import Person from '@material-ui/icons/Person';
import { useTranslation } from 'react-i18next';

const SignInButton = ({onClickSignIn})=> {

    const { t, i18n } = useTranslation('common');

    return(
        <button type="button" className="nav-button-SignIn" onClick={onClickSignIn}>
            <Person style={{fontSize: 30, color: '#fff'}}/>
           <span>{t('common:sign-in')}</span>
        </button>
    )
}

export default SignInButton;