import React, { useEffect } from 'react';
import Modal from 'react-awesome-modal';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import AppColors from '../assets/colors';
import { inject, observer } from "mobx-react";
import { useTranslation } from 'react-i18next';

const VerificationErrorModal = ({visible, exit, resend, feathersStore})=> {
  const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },
  }));

  const { t, i18n } = useTranslation('common');

  useEffect(()=> {
    i18n.changeLanguage(feathersStore.language);    
  },[feathersStore.language]);
    
  return (
    <section>
      <Modal visible={visible} width="400" height="150" effect="fadeInUp" >
        <div className="modalContainer">
          <div className="modalTitleContainer">
            <p style={{color: AppColors.secondary}}>{t("common:error-code")}</p>
          </div>
          <div className="confirmationModalButtonsView">
            <Button 
              style={{color: 'rgb(137, 170, 169)', fontSize: 18}} 
              onClick={resend}
            >{t("common:repeat")}</Button>
            <h3 style={{color: AppColors.secondary, marginTop: 10}}>|</h3>
            <Button 
              style={{color: AppColors.red, fontSize: 18}} 
              onClick={exit}
            >{t("common:exit")}</Button>
          </div>
        </div>
      </Modal>
    </section>
  );
}

export default inject('feathersStore')(observer(VerificationErrorModal));