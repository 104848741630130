import React from 'react'

const TermsText = ()=> {
    return(
        <p>Για την ολοκλήρωση των παραγγελιών σας θα σας ζητηθεί κάθε φορά ξεχωριστά να επιβεβαιώνετε ότι συμφωνείτε με τους κάτωθι όρους χρήσεως.

        – Tα πνευματικά δικαιώματα του Περιεχομένου (π.χ. προγράμματα, πληροφορίες, δεδομένα) και των Υπηρεσιών του διαδικτυακού τόπου (site) https://287arthub.com που έχουν εισαχθεί στο 
        Δίκτυο, προστατεύονται από τους ελληνικούς, κοινοτικούς και διεθνείς νόμους περί πνευματικής ιδιοκτησίας.
        
        – Απαγορεύεται οποιαδήποτε αντιγραφή, διανομή, μεταφορά, μεταποίηση, μεταπώληση, δημιουργία παράγωγης εργασίας ή παραπλάνηση του κοινού σχετικά με τον πραγματικό παροχέα τους, 
        του Περιεχομένου διαδικτυακού τόπου. Τυχόν αναπαραγωγή, επανέκδοση, φόρτωση, ανακοίνωση, διάδοση ή μετάδοση ή οποιαδήποτε άλλη χρήση του Περιεχομένου με οποιοδήποτε τρόπο ή μέσο 
        για εμπορικούς ή άλλους σκοπούς επιτρέπεται μόνο κατόπιν προηγούμενης γραπτής άδειας του https://287arthub.com ή οποιουδήποτε άλλου δικαιούχου των πνευματικών δικαιωμάτων.
        
        – Το ηλεκτρονικό κατάστημα https://287arthub.com παρέχει το Περιεχόμενο (π.χ. πληροφορίες, ονόματα, φωτογραφίες, απεικονίσεις), τα προϊόντα και τις υπηρεσίες που διατίθενται μέσω του 
        διαδικτυακού τόπου “όπως ακριβώς έχουν”. Σε καμία περίπτωση το ηλεκτρονικό κατάστημα https://287arthub.com δεν φέρει ευθύνη για τυχόν απαιτήσεις νομικής ή αστικής ή/και ποινικής φύσεως 
        ούτε για τυχόν ζημία (θετική, ειδική ή αποθετική, η οποία ενδεικτικά και όχι περιοριστικά, διαζευκτικά ή και σωρευτικά συνίσταται σε απώλεια κερδών, δεδομένων, διαφυγόντα κέρδη, 
        χρηματική ικανοποίηση κλπ.) από επισκέπτες του διαδικτυακού τόπου ή τρίτους από αιτία που έχει σχέση με τη λειτουργία ή μη ή και τη χρήση του διαδικτυακού τόπου ή και σε αδυναμία 
        παροχής υπηρεσιών ή και πληροφοριών που διατίθενται από αυτόν ή και από τυχόν μη επιτρεπόμενες παρεμβάσεις τρίτων σε προϊόντα ή και υπηρεσίες ή και πληροφορίες που διατίθενται 
        μέσω αυτού. Οι φωτογραφίες των προϊόντων είναι ενδεικτικές και δε φέρουμε καμία ευθύνη για τυχόν ορθογραφικά λάθη.
        
        – Οι τιμές μπορούν να αλλάξουν χωρίς προειδοποίηση. Δέσμευση τιμής μπορεί να γίνει μόνο ηλεκτρονικής παραγγελίας και ολοκληρώνεται με την αποστολή της επιβεβαίωσή της. Το κατάστημα 
        με την  εμπορική ονομασία https://287arthub.com δεν υποχρεούται να παράσχει ουδεμία εγγύηση για τη διαθεσιμότητά των προϊόντων του, αλλά αναλαμβάνει να ενημερώνει άμεσα τους πελάτες του 
        για τυχόν ελλείψεις ή καθυστερημένες αποστολές.
        
        – Ο πελάτης από τη στιγμή που θα επεξεργαστεί η παραγγελία από το τμήμα παραγγελιών και έχει επιβεβαιώσει την παραγγελία του, εξασφαλίζει πως θα παραλάβει το προϊόν με την τιμή την 
        οποία “αποδέχτηκε” τη συναλλαγή έστω και αν η τιμή στην πορεία μεταβληθεί. Σε περίπτωση που τα προϊόντα ή οι υπηρεσίες αναφέρονται στον δικτυακό τόπο με λάθος τιμή λόγω τυπογραφικού 
        σφάλματος τότε οι παραγγελίες ακυρώνονται αυτόματα. To https://287arthub.com δεν ευθύνεται για τυχόν ζημιές που προκύψουν στον πελάτη/χρήστη του ηλεκτρονικού καταστήματος από την εκτέλεση 
        ή μη των παραγγελιών ή από την καθυστέρηση εκτέλεσης για οποιονδήποτε λόγο.
        
        – Το ηλεκτρονικό κατάστημα https://287arthub.com διατηρεί το δικαίωμα να τροποποιεί, προσθέτει, μεταβάλλει το περιεχόμενο ή τις υπηρεσίες του διαδικτυακού τόπου, καθώς και τους όρους 
        χρήσης, οποτεδήποτε το κρίνει αναγκαίο και χωρίς προηγούμενη προειδοποίηση, με μόνη την ανακοίνωσή τους μέσω του διαδικτυακού της τόπου.
        
        ΣΧΕΤΙΚΑ ΜΕ ΤΗΝ ΕΚΔΟΣΗ ΤΙΜΟΛΟΓΙΟΥ ΒΑΣΕΙ ΤΟΥ ΝΟΜΟΥ 39Α(Ν.2859/2000) ΑΚΟΛΟΥΘΗΣΤΕ ΤΙΣ ΠΑΡΑΚΑΤΩ ΟΔΗΓΙΕΣ:
        
        1. ΑΠΟΣΤΟΛΗ ΤΩΝ ΑΠΑΡΑΙΤΗΤΩΝ ΕΓΓΡΑΦΩΝ ΜΕΣΩ TAXISNET ΤΗΣ ΕΤΑΙΡΙΑΣ ΣΑΣ ΣΤΟ EMAIL ΤΟΥ ΚΑΤΑΣΤΗΜΑΤΟΣ ΜΑΣ anthiaem@gmail.com.
        
        2. ΟΣΟ ΑΦΟΡΑ ΤΗΝ ΠΛΗΡΩΜΗ ΤΩΝ ΕΝ ΛΟΓΩ ΠΑΡΑΓΓΕΛΙΩΝ ΘΑ ΓΙΝΕΤΑΙ ΑΥΣΤΗΡΑ ΜΕ ΤΡΑΠΕΖΙΚΗ ΚΑΤΑΘΕΣΗ ΑΠΟ ΕΤΑΙΡΙΚΟ ΛΟΓΑΡIΑΣΜΟ Η ΜΕΣΩ ΕΤΑΙΡΙΚΗΣ ΚΑΡΤΑΣ.
        
        3. ΕΦΟΣΟΝ ΤΟ ΛΟΓΙΣΤΗΡΙΟ ΤΟΥ ΚΑΤΑΣΤΗΜΑΤΟΣ ΜΑΣ ΚΑΝΕΙ ΤΟΥΣ ΕΛΕΓΧΟΥΣ ΓΙΑ ΤΗΝ ΔΙΑΣΤΑΥΡΩΣΗ ΤΗΣ ΟΡΘΟΤΗΤΑΣ ΤΩΝ ΣΤΟΙΧΕΙΩΝ ΣΑΣ ΙΣΧΥΟΥΝ ΤΑ ΕΞΗΣ: ΟΣΟ ΑΦΟΡΑ ΤΗΝ ΠΑΡΑΛΑΒΗ ΤΟΥ ΠΡΟΙΟΝΤΟΣ ΘΑ 
        ΓΙΝΕΤΑΙ ΑΠΟ ΤΟΝ ΝΟΜΙΜΟ ΕΚΠΡΟΣΩΠΟ Η ΟΠΟΙΟΔΗΠΟΤΕ ΑΛΛΟ ΜΕ ΕΞΟΥΣΙΟΔΟΤΗΣΗ ΠΟΥ ΦΕΡΕΙ ΤΟ ΓΝΗΣΙΟ ΤΗΣ ΥΠΟΓΡΑΦΗΣ ΑΠΟ ΤΗΝ ΕΔΡΑ ΤΟΥ ΚΑΤΑΣΤΗΜΑΤΟΣ ΜΑΣ ΚΑΙ ΜΕ ΕΠΙΔΕΙΞΗ ΤΑΥΤΟΤΗΤΑΣ .
        
        4. ΟΙ ΠΕΛΑΤΕΣ ΠΡΕΠΕΙ ΝΑ ΕΙΝΑΙ ΕΝΗΜΕΡΟΙ ΠΩΣ Η ΠΑΡΑΓΓΕΛΙΑ ΤΟΥΣ ΑΦΟΡΑ ΤΗΝ ΤΙΜΗ ΠΡΟΣΦΟΡΑΣ ΚΑΙ ΕΧΟΥΝ ΔΥΝΑΤΟΤΗΤΑ ΝΑ ΠΡΟΜΗΘΕΥΤΟΥΝ ΜΕΧΡΙ 1 ΤΕΜΑΧΙΟ. ΤΕΛΟΣ, ΓΝΩΡΙΖΟΥΜΕ ΠΩΣ Η ΠΑΡΑΠΑΝΩ ΜΗ 
        ΤΗΡΗΣΗ ΑΠΟ ΤΟΥΣ ΠΕΛΑΤΕΣ ΚΑΘΙΣΤΟΥΝ ΤΗΝ ΑΥΤΟΜΑΤΗ ΑΚΥΡΩΣΗ ΤΗΣ ΚΡΑΤΗΣΗΣ ΕΝΤΟΣ 48 ΩΡΩΝ ΑΠΟ ΤΗΝ ΤΗΛΕΦΩΝΙΚΗ ΜΑΣ ΕΠΙΒΕΒΑΙΩΣΗ.
        
        – Η εταιρεία μας και εσείς υπαγόμαστε στην αποκλειστική δικαιοδοσία των ελληνικών δικαστηρίων όσον αφορά την επίλυση διενέξεων που ενδεχομένως προκύψουν από την παρούσα Σύμβαση. 
        Σε περίπτωση που οποιαδήποτε διάταξη της παρούσας Σύμβασης κριθεί παράνομη, άκυρη ή για οποιονδήποτε λόγο μη εφαρμόσιμη, η διάταξη αυτή θα θεωρείται διαιρετή και δεν θα επηρεάζει 
        την εγκυρότητα και εφαρμοσιμότητα των υπόλοιπων διατάξεων.
        
        Πολιτική Επιστροφών – Δικαίωμα Υπαναχώρησης
        
        Εάν ο πελάτης είναι φυσικό πρόσωπο (καταναλωτής) που καταρτίζει την πώληση για προσωπική χρήση κι όχι για την εξυπηρέτηση της ατομικής του δραστηριότητας, τότε δικαιούται να 
        υπαναχωρήσει από την πώληση εντός 14 ημερολογιακών ημερών, επιστρέφοντας το προϊόν στην αρχική του κατάσταση, επιβαρυνόμενος με τα έξοδα επιστροφής, σύμφωνα με την § 10 του άρθρου 
        4 Ν. 2251/1994.
        
        Δικαιούστε να υπαναχωρήσετε αναιτιολογήτως από την εξ’ αποστάσεως αγορά, εγγράφως, εντός 14 ημερολογιακών ημερών επιστρέφοντας το προϊόν στην αρχική του κατάσταση. Το δικαίωμα αυτό 
        εκκινεί από την ημέρα παραλαβής των προϊόντων. Η εντός 14 ημερολογιακών ημερών αποστολή της δήλωσης υπαναχώρησης ή αποστολή των προϊόντων, θα θεωρείται αρκετή για την εμπρόθεσμη 
        άσκηση του δικαιώματος υπαναχώρησης. Η δήλωση αποστέλλεται προς το κατάστημα με εμπορικό σήμα 287arthub.com με τη μορφή φόρμας RMA.
        
        Τα προϊόντα επιστρέφονται με έξοδα του πελάτη ή προσκομίζονται από τον ίδιο τον πελάτη στην έδρα της επιχείρησης που εδρεύει Βαλαωρίτου 4, 15452 Π. Ψυχικό.
        
        Συνέπειες υπαναχώρησης
        
        Σε περίπτωση έγκυρης άσκησης του δικαιώματος υπαναχώρησης, θα πρέπει να αποκατασταθεί με χρηματική αποζημίωση κάθε τυχόν απομείωση της αξίας του εμπορεύματος, μόνο στην περίπτωση και 
        στον βαθμό που αυτή οφείλεται στη μεταχείριση του προϊόντος που εκφεύγει της ειθισμένης εξέτασης των χαρακτηριστικών και της λειτουργικότητας του προϊόντος. Ως «ειθισμένη εξέταση 
        των χαρακτηριστικών και της λειτουργικότητας του προϊόντος», εννοείται η δοκιμή των σχετικών αγαθών, όπως αυτή είναι δυνατό να ελάμβανε χώρα και όπως συνηθίζεται σε ένα φυσικό 
        κατάστημα.
        
        Ο πελάτης θα βαρύνεται με τα έξοδα επιστροφής. Κάθε χρηματική υποχρέωση για την καταβολή ποσών (π.χ. εξόδων μεταφοράς, αποζημίωσης) θα πρέπει να εκπληρώνεται εντός 30 ημερών, 
        εκκινώντας από τον χρόνο αποστολής της δήλωσης υπαναχώρησης από εσάς ή της επιστροφής του εμπορεύματος.
        
        Το δικαίωμα υπαναχώρησης εκ του άρθρου 4 § 10 ν. 2251/1994 δεν εφαρμόζεται:
        
        Στις περιπτώσεις που το τίμημα των προϊόντων έχει καταβληθεί στο φυσικό μας κατάστημα ( Βαλαωρίτου 4, 15452 Π. Ψυχικό ) και ταυτόχρονα, αυτά έχουν παραληφθεί από το φυσικό 
        κατάστημα, καθώς η πώληση δεν θεωρείται ότι τελέστηκε από απόσταση.
        
        Σε περίπτωση έγκυρης άσκησης του δικαιώματος υπαναχώρησης και με την προϋπόθεση ότι έχει επιστραφεί το προϊόν στην έδρα του 287arthub.com, η αξία του προϊόντος θα επιστρέφεται 
        στον πελάτη. Ρητώς συμφωνείται και ο πελάτης παρέχει ήδη την ανέκκλητη εντολή και συναίνεσή του προς τούτο, η εταιρεία μας να δικαιούται να παρακρατά από το επιστρεφόμενο ποσό 
        τα έξοδα επιστροφής, τα οποία βαρύνουν τον πελάτη καθώς και τυχόν αποζημίωση σε περίπτωση καταστροφής ή απομείωσης της αξίας του προϊόντος λόγω φθοράς ή βλάβης του προϊόντος 
        οφειλόμενης σε ενέργειες του πελάτη.
        
        Ανάλογα με τον τρόπο πληρωμής που έχει επιλέξει ο πελάτης κατά την παραγγελία του θα  γίνει αντίστοιχα και η επιστροφή χρημάτων:
        
        Για Πιστωτική κάρτα on-line η επιστροφή των χρημάτων θα γίνει στην κάρτα.
        Για Αντικαταβολή ή κατάθεση σε τράπεζα θα πρέπει να μας δώσετε το IBAN ενός τραπεζικού λογαριασμού, το ονοματεπώνυμο του δικαιούχου όπως και την τράπεζα την οποία αφορά.</p>
    )
}

export default TermsText;