import React, {useState, useEffect} from 'react';
import Camera from '../buttons/camera';
import Lock from '@material-ui/icons/Lock'
import CloseIcon from '@material-ui/icons/Close';
import ExpandMore from '@material-ui/icons/ExpandMore';
import 'react-slidedown/lib/slidedown.css'
import ChangePassword from '../screenComponents/changePassword'
import GreenSubmitButton from '../buttons/greenSubmitButton';
import { 
    FormControl,
    FormHelperText,
    Input    
  } from '@material-ui/core';
import { inject, observer } from "mobx-react";
import Utils from '../../Utils';
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';

let firstNameElement;
let lastNameElement;
let phoneElement;

const ProfileSettings = ({feathersStore})=> {

  const { t, i18n } = useTranslation(['common', 'account']);

  useEffect(()=> {
    i18n.changeLanguage(feathersStore.language);    
  },[feathersStore.language]);

  const [email, setEmail] = useState(feathersStore.user.email); 
  const [firstName, setFirstName] = useState(feathersStore.user.firstname); 
  const [lastName, setLastName] = useState(feathersStore.user.lastname); 
  const [phone, setPhone] = useState(feathersStore.user.phone); 
  const [firstNameError, setFirstNameError] = useState(false); 
  const [lastNameError, setLastNameError] = useState(false); 
  const [phoneError, setPhoneError] = useState(false); 
  const [loading, setLoading] = useState(false);  
  const [activeDropdown, setActiveDropdown] = useState(false);
    
  const firstNameChange = event => {    
    setFirstName(event.target.value);
    firstNameValidation(event.target.value);   
  };

  const lastNameChange = event => {    
    setLastName(event.target.value);
    lastNameValidation(event.target.value);   
  };

  const phoneChange = event => {    
    setPhone(event.target.value);
    phoneValidation(event.target.value);   
  };

  const firstNameValidation = val => {
    if (!Utils.validateName(val) ) {
      setFirstNameError(true);
    }else{
     setFirstNameError(false);
   }
  }

  const lastNameValidation = val => {
    if (!Utils.validateName(val) ) {
      setLastNameError(true);
    }else{
     setLastNameError(false);
   }
  }

  const phoneValidation = val => {
    if (!Utils.validatePhone(val) ) {
      setPhoneError(true);
    }else{
     setPhoneError(false);
   }
  } 

  const enterFirstNamePressed = (ev) => {   
    if (ev.key === 'Enter') {
        lastNameElement.focus();
    }
  }

  const enterLastNamePressed = (ev) => {
    if (ev.key === 'Enter') {
      phoneElement.focus();
    }
  }

  const enterPhonePressed = (ev) => {
    if (ev.key === 'Enter') {
      firstNameElement.focus();
    }
  }

  const submitEditing = async(ev) => {
    if (ev.key === 'Enter') {
      updateUser();
    }
  }

  const updateUser = () => {
    setLoading(true);
    feathersStore.patchUser(feathersStore.user._id, firstName, lastName, phone)
    .then(user => {
      feathersStore.setUser(user);
       setLoading(false);
      alert(t("account:update-alert"));
    })
    .catch(error => {
      setLoading(false);
      alert(`${t("account:update-alert")}:  ${error.message}`)
    });
  };

  const toggleChangePassword = () => {
    setActiveDropdown(!activeDropdown)
  }

  return(
    <div className="container">
      <div className="change-pass-container">
        <div>
          <Camera />
        </div>
        <div className="settingsFieldSub">
          <FormControl fullWidth={false}>
            <Input
              value={firstName}
              error={firstNameError}
              inputRef={r => {
                firstNameElement = r;
              }}
              onChange={firstNameChange}
              onKeyPress={enterFirstNamePressed}
              placeholder={t("common:firstName") + "*"}
            />
            {firstNameError && <FormHelperText id="component-error-text" >{t("common:firstNameError")}</FormHelperText>}
          </FormControl>
          <FormControl fullWidth={false}>
            <Input
              value={lastName}
              error={lastNameError}
              inputRef={r => {
                lastNameElement = r;
              }}
              onChange={lastNameChange}
              onKeyPress={enterLastNamePressed}
              placeholder={t("common:lastName") + "*"}
            />
            {lastNameError && <FormHelperText id="component-error-text" >{t("common:lastNameError")}</FormHelperText>}
          </FormControl>
          <FormControl fullWidth={false} disabled={true}> 
            <Input
              value={email} 
              placeholder={t("common:email") + "*"}
            />
          </FormControl>
          <FormControl fullWidth={false}>
            <Input
              value={phone}
              error={phoneError}
              inputRef={r => {
                phoneElement = r;
              }}
              onChange={phoneChange}
              onKeyPress={enterPhonePressed}
              placeholder={t("common:phone") + "*"}
            />
            {phoneError && <FormHelperText id="component-error-text" >{t("common:phoneError")}</FormHelperText>}
          </FormControl>
        </div>
      </div>
        <GreenSubmitButton 
          title={t("common:save")}
          disabled = {!firstName || !lastName || !phone||
            firstNameError || lastNameError || phoneError}
          loading={loading}
          onClick={updateUser}
        /> 
        <div className="change-password">
          <div className="changePassTitle">
            <Lock style={{color: '#333', fontSize: 30}}/>
            <h4 style={{color: '#333', cursor: 'pointer'}} onClick={() => toggleChangePassword()}>{t("account:psw")}</h4>
          </div>
          {activeDropdown ? 
          <button onClick={() => toggleChangePassword()} style={{border: 'none', outline: 'none'}}>
            <CloseIcon style={{color: 'red', fontSize: 30}} />
          </button>
          :
          <button onClick={() => toggleChangePassword()} style={{border: 'none', outline: 'none'}}>
            <ExpandMore style={{color: 'red', fontSize: 35}}/>
          </button>
          }
        </div>
        <CSSTransition
          in={activeDropdown}
          timeout={300}
          classNames="alert"
          unmountOnExit
        >
        <ChangePassword />
      </CSSTransition>
    </div>
  )
}

export default inject('feathersStore')(observer(ProfileSettings));