import React from 'react'

const PrivacyText = ()=> {
    return(
        <p>Για την Άνθια Μαζαράκη η προστασία των προσωπικών δεδομένων έχει πρωταρχική σημασία και αντιμετωπίζεται με τη μέγιστη σοβαρότητα.
        Ο σεβασμός προς τα προσωπικά δεδομένα που διαχειριζόμαστε και η διασφάλιση της σωστής επεξεργασίας τους είναι μία από τις προτεραιότητες της Εταιρίας. Για το λόγο αυτό λαμβάνουμε 
        όλα τα κατάλληλα τεχνικά και οργανωτικά μέτρα για να προστατέψουμε τα προσωπικά δεδομένα που επεξεργαζόμαστε και να διασφαλίσουμε ότι η επεξεργασία τους πραγματοποιείται πάντα 
        σύμφωνα με τις απαιτήσεις που τίθενται από το ισχύον νομοθετικό πλαίσιο και κυρίως από το Γενικό Κανονισμό Προστασίας Δεδομένων (ΕΕ) 2016/679.
        <br />
        <b>1. ΥΠΕΥΘΥΝΟΣ ΕΠΕΞΕΡΓΑΣΙΑΣ – ΣΤΟΙΧΕΙΑ ΕΠΙΚΟΙΝΩΝΙΑΣ</b> 
        Η Άνθια Μαζαράκη ενεργεί ως Υπεύθυνος Επεξεργασίας για όλα τα προσωπικά δεδομένα που συλλέγει, υποβάλλει σε επεξεργασία και αποθηκεύει.
        Όνομα: Άνθια ΜΑζαράκη
        Διεύθυνση: Βαλαωρίτου 4, 15452 Π. Ψυχικό 
        Τηλέφωνο: +30 2106714123
        Ηλεκτρονικό Ταχυδρομείο: anthiaem@gmail.com
        Υπεύθυνος Επικοινωνίας: Άνθια Μαζαράκη
        <br />
        <b>2. ΠΟΙΑ ΔΕΔΟΜΕΝΑ ΣΥΛΛΕΓΟΥΜΕ ΚΑΙ ΕΠΕΞΕΓΑΖΟΜΑΣΤΕ</b> 
        Για να σας εξυπηρετήσουμε και να σας προσφέρουμε τις υπηρεσίες και τα προϊόντα μας συλλέγουμε και επεξεργαζόμαστε όλα τα 
        δεδομένα που γνωστοποιείτε στον εκπρόσωπο του τηλεφωνικού κέντρου όταν δίνετε την παραγγελία σας ή όσα δεδομένα συμπληρώνετε στα αντίστοιχα πεδία όταν ανοίγετε λογαριασμό χρήστη 
        στην ιστοσελίδα μας και πιο συγκεκριμένα, ονοματεπώνυμο, ταχυδρομική διεύθυνση, τηλέφωνο επικοινωνίας (σταθερό ή κινητό), e-mail, φορολογικά στοιχεία (στην περίπτωση που επιθυμείτε έκδοση τιμολογίου).
        Παρακαλούμε λάβετε υπόψη σας ότι η ιστοσελίδα της Εταιρίας μας ενδέχεται να περιέχει συνδέσμους (links) προς άλλους ιστότοπους. H Εταιρία μας δεν ευθύνεται για τις πρακτικές 
        απορρήτου και προστασίας προσωπικών δεδομένων ή/και το περιεχόμενο αυτών των ιστοτόπων. Αν είστε ηλικίας μέχρι 15 ετών, παρακαλούμε να λάβετε την άδεια του γονέα ή κηδεμόνα σας, 
        προτού μας κοινοποιήσετε οποιαδήποτε προσωπικά στοιχεία σας.
        <br />
        <b>3. ΣΚΟΠΟΙ ΕΠΕΞΕΡΓΑΣΙΑΣ & ΝΟΜΙΚΕΣ ΒΑΣΕΙΣ ΕΠΕΞΕΡΓΑΣΙΑΣ</b> 
        Όλα τα ανωτέρω προσωπικά δεδομένα συλλέγονται και υπόκεινται σε επεξεργασία με σκοπό καταρχήν την εκτέλεση της μεταξύ μας σύμβασης πώλησης των προϊόντων μας και πιο συγκεκριμένα 
        για την ετοιμασία και παράδοση της παραγγελίας σας και την επικοινωνία μας μαζί σας σχετικά με αυτή. Κάποια από τα δεδομένα σας μπορεί να χρησιμοποιηθούν και για τους παρακάτω 
        σκοπούς πάντα με τη δική σας σχετική συγκατάθεση:
        - Επικοινωνία μαζί σας σε σχέση με διαγωνισμούς, κληρώσεις και άλλες εκδηλώσεις
        - Ενημέρωση και αποστολή προσφορών και ενημερωτικού υλικού σχετικά με τα προϊόντα και τις υπηρεσίες μας
        - Στατιστικοί σκοποί και έρευνες
        - Προώθηση και προαγωγή των προϊόντων και υπηρεσιών μας
        Νομικές βάσεις επεξεργασίας των δεδομένων μπορεί να είναι κατά περίπτωση:
        i. Η εκτέλεση και ολοκλήρωση της μεταξύ μας σύμβασης για την πώληση των προϊόντων μας σε εσάς (προετοιμασία και παράδοση της παραγγελίας σας). Χωρίς τη συλλογή και χρήση των 
        παραπάνω πληροφορίων δεν θα είναι δυνατόν να σας εξυπηρετήσουμε
        ii. Η συγκατάθεση που μπορεί εσείς να μας παρέχετε προκειμένου να λαμβάνετε ενημερώσεις σχετικά με προσφορές ή προωθητικό υλικό για τα προϊόντα και τις υπηρεσίες μας, 
        τις εμπορικές δράσεις και δραστηριότητες που αναπτύσσουμε κλπ. Μπορείτε να ανακαλέσετε τη συγκατάθεσή σας όποτε εσείς το επιθυμείτε, επικοινωνώντας μαζί μας στα στοιχεία 
        επικοινωνίας που αναφέρονται στην παράγραφο 1 ή μέσω της ιστοσελίδας μας.
        <br />
        <b>4. ΠΟΥ ΚΟΙΝΟΠΟΙΟΥΜΕ ΤΑ ΠΡΟΣΩΠΙΚΑ ΔΕΔΟΜΕΝΑ ΣΑΣ</b> 
        Τα προσωπικά δεδομένα σας είναι πιθανό να κοινοποιηθούν στους ακόλουθους αποδέκτες:
        - Στους franchisees, στους υπεύθυνους καταστημάτων του δικτύου της Άνθιας Μαζαράκη και στους διανομείς προκειμένου να ετοιμάσουν και να παραδώσουν την παραγγελία
        - Σε εταιρία αποστολής newsletter (εφόσον έχετε δώσει τη συγκατάθεσή σας για να σας αποστέλλουμε newsletter)
        - Σε εταιρία αποστολής sms (στην περίπτωση που έχετε δώσει τη συγκατάθεσή σας για να λαμβάνετε ενημερώσεις)
        - Σε συνεργαζόμενες εταιρίες, προκειμένου να μπορέσετε να παραλάβετε το δώρο σας, εφόσον είστε νικητές σε κάποιο διαγωνισμό στον οποίο λάβατε μέρος (κάθε φορά που οργανώνουμε 
        ένα διαγωνισμό, αναφέρουμε στους όρους του διαγωνισμού το γεγονός της κοινοποίησης των στοιχείων σας στη συνεργαζόμενη εταιρία)
        - Σε συνεργαζόμενες εταιρίες διοργάνωσης εκδηλώσεων (στην περίπτωση που θέλετε να διοργανώσετε κάποιο πάρτι)
        - Σε εξωτερικό συνεργάτη που μας υποστηρίζει σε θέματα λογισμικού
        - Στο συνεργαζόμενο νομικό τμήμα της εταιρίας, σε περίπτωση δικαστικής ή εξώδικης διένεξης
        - Σε αστυνομικές ή άλλες κρατικές αρχές εφόσον μας ζητηθεί κάτι τέτοιο επίσημα ή μετά από εισαγγελική παραγγελία
        <br />
        <b>5. ΧΡΟΝΙΚΟ ΔΙΑΣΤΗΜΑ ΑΠΟΘΗΚΕΥΣΗΣ ΤΩΝ ΔΕΔΟΜΕΝΩΝ</b>
        Τα δεδομένα σας τηρούνται στη βάση δεδομένων του Arthub για 5 χρόνια μετά την τελευταία συναλλαγή σας με την εταιρία ή για όσο χρόνο μπορεί να απαιτεί η νομοθεσία (π.χ. 
        στην περίπτωση έκδοσης τιμολογίου, όσο χρόνο ορίζει το φορολογικό νομοθετικό πλαίσιο).
        Αν η επεξεργασία βασίζεται στη συγκατάθεσή σας, τα προσωπικά δεδομένα τηρούνται μέχρι να ανακαλέσετε τη συγκατάθεση αυτή. Διευκρινίζεται ότι η ανάκληση της συγκατάθεσης δεν 
        θίγει τη νομιμότητα της επεξεργασίας που βασίστηκε στη συγκατάθεση πριν την ανάκλησή της.
        Σε κάθε περίπτωση η Άνθια Μαζαράκη εφαρμόζει όλα τα κατάλληλα τεχνικά και οργανωτικά μέτρα για να εξασφαλίζει την προστασία των προσωπικών δεδομένων που επεξεργάζεται και μεριμνά 
        συνεχώς για την αποτροπή κάθε μη εξουσιοδοτημένης πρόσβασης στα δεδομένα αυτά.
        <br />
        <b>6. ΔΙΚΑΙΩΜΑΤΑ ΠΟΥ ΕΧΕΤΕ ΣΕ ΣΧΕΣΗ ΜΕ ΤΑ ΠΡΟΣΩΠΙΚΑ ΔΕΔΟΜΕΝΑ ΣΑΣ</b>
        Αναφορικά με την επεξεργασία των προσωπικών δεδομένων σας μπορείτε να ασκήσετε τα παρακάτω δικαιώματα:
        <b> Δικαίωμα πρόσβασης </b>
        Έχετε δικαίωμα να γνωρίζετε ποια δεδομένα σας τηρούμε και επεξεργαζόμαστε, για ποιο λόγο και άλλες συμπληρωματικές πληροφορίες σχετικά με αυτά, καθώς και να μας ζητήσετε αντίγραφό τους
        <b> Δικαίωμα διόρθωσης </b>
        Έχετε δικαίωμα να ζητήσετε τη διόρθωση, τροποποίηση και συμπλήρωση των προσωπικών δεδομένων σας
        <b> Δικαίωμα διαγραφής («δικαίωμα στη λήθη») </b>
        Έχετε δικαίωμα να ζητήσετε τη διαγραφή των προσωπικών δεδομένων σας όταν αυτά τίθενται σε επεξεργασία μετά τη σχετική συγκατάθεσή σας. Στις περιπτώσεις που η επεξεργασία 
        βασίζεται σε άλλη νομική βάση (όπως ενδεικτικά στην εκτέλεση σύμβασης, σε έννομη υποχρέωση ή στην προστασία έννομων συμφερόντων της Εταιρίας κλπ.) το δικαίωμά σας αυτό μπορεί 
        να υπόκειται σε περιορισμούς ή να μην υφίσταται
        <b> Δικαίωμα περιορισμού της επεξεργασίας </b>
        Έχετε δικαίωμα να ζητήσετε τον περιορισμό της επεξεργασίας των προσωπικών δεδομένων σας
        - όταν αμφισβητείται η ακρίβειά τους και μέχρι να γίνει η σχετική επαλήθευση
        - εναλλακτικά, αντί για τη διαγραφή τους
        - όταν δεν μας είναι πλέον απαραίτητα για τους σκοπούς επεξεργασίας για τους οποίους τα συλλέξαμε, είναι όμως απαραίτητα για τη θεμελίωση, άσκηση ή υποστήριξη νομικών αξιώσεων από εσάς
        - όταν έχετε αντιρρήσεις για την επεξεργασία τους και μέχρι να επαληθευτεί ότι υπάρχουν νόμιμοι λόγοι για την επεξεργασία αυτή από την Εταιρία
        <b> Δικαίωμα εναντίωσης στην επεξεργασία </b>
        Έχετε δικαίωμα να εναντιωθείτε στην επεξεργασία των προσωπικών δεδομένων σας όταν αυτή γίνεται με βάση έννομο συμφέρον, καθώς και για σκοπούς απευθείας εμπορικής προώθησης και 
        κατάρτισης προφίλ
        <b> Δικαίωμα στη φορητότητα </b>
        Έχετε δικαίωμα να ζητήσετε και να λάβετε τα προσωπικά δεδομένα σας σε μορφή που σας επιτρέπει την πρόσβαση σε αυτά, τη χρήση και επεξεργασία τους με τις κοινώς διαδεδομένες 
        μεθόδους επεξεργασίας. Επιπλέον, για τα δεδομένα σας που επεξεργαζόμαστε με αυτοματοποιημένα μέσα και με βάση τη συγκατάθεσή σας ή για την εκτέλεση σύμβασης, μπορείτε να μας 
        ζητήσετε να τα διαβιβάσουμε απευθείας σε άλλο υπεύθυνο επεξεργασίας, εφόσον αυτό είναι τεχνικά εφικτό
        <b> Δικαίωμα ανάκλησης συγκατάθεσης </b>
        Αν η επεξεργασία των δεδομένων σας βασίζεται στη συγκατάθεσή σας, έχετε δικαίωμα να την ανακαλέσετε οποιαδήποτε στιγμή. Η ανάκληση της συγκατάθεσής σας δεν θίγει τη νομιμότητα 
        της επεξεργασίας που βασίστηκε στη συγκατάθεση πριν την ανάκλησή της
        Για την άσκηση των παραπάνω δικαιωμάτων σας και για οποιαδήποτε ερώτημα, παράπονο ή άλλη ενημέρωση σχετικά με την επεξεργασία των προσωπικών δεδομένων σας μπορείτε να 
        επικοινωνείτε μαζί μας στο email anthiaem@gmail.com ή στα στοιχεία επικοινωνίας που αναφέρονται στην παράγραφο 1.
        <br />
        <b>7. ΔΙΚΑΙΩΜΑΤΑ ΚΑΤΑΓΓΕΛΙΑΣ ΣΤΗΝ ΑΡΧΗ ΠΡΟΣΤΑΣΙΑΣ ΔΕΔΟΜΕΝΩΝ ΠΡΟΣΩΠΙΚΟΥ ΧΑΡΑΚΤΗΡΑ (ΑΠΔΠΧ) </b> 
        Έχετε δικαίωμα να υποβάλετε καταγγελία στην Αρχή Προστασίας Δεδομένων Προσωπικού Χαρακτήρα (www.dpa.gr) σχετικά με θέματα που αφορούν την επεξεργασία των προσωπικών δεδομένων σας
        <br />
        <b>8. ΑΛΛΑΓΕΣ ΣΤΗΝ ΠΟΛΙΤΙΚΗ ΠΡΟΣΤΑΣΙΑΣ ΠΡΟΣΩΠΙΚΩΝ ΔΕΔΟΜΕΝΩΝ </b> 
        Τυχόν μελλοντικές αλλαγές στην Πολιτική Προστασίας Προσωπικών Δεδομένων της Εταιρίας μας θα αναρτηθούν στην ιστοσελίδα της https://287arthub.com και όπου απαιτείται, θα σας κοινοποιηθούν.
    </p>
    )
}

export default PrivacyText;