import React, { useEffect, useRef, useState } from 'react';
import { inject, observer } from "mobx-react";
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import "./Contact.css";
import { useTranslation } from 'react-i18next';
import { PHONE_REGEX } from "../../components/assets/globalConstants";
import SmallAlert from "../../components/modals/smallAlert";
import GreenSubmitButton from '../../components/buttons/greenSubmitButton';
import Colors from "../../components/assets/colors";

const Contact = ({ feathersStore })=> {

  const { t, i18n } = useTranslation(['contact']);
  const formRef = useRef();

  const [showError, setShowError] = useState(false);
  const [ showSuccess,setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  
  useEffect(()=> {
    i18n.changeLanguage(feathersStore.language);
  },[feathersStore.language]);
  
  useEffect(()=> {  
    formRef.current.resetForm();  
    formRef.current.validateForm();  
  },[feathersStore.user]);

  const validationSchema = Yup.object({
    firstName: Yup.string()
    .min(2, t('contact:must-have-char'))
    .max(255, t('contact:less255'))
    .required(t('contact:required')),
    lastName: Yup.string()
    .min(2, t('contact:must-have-char'))
    .max(255, t('contact:less255'))
    .required(t('contact:required')),
    email: Yup.string()
    .email(t('contact:invalidEmail'))
    .required(t('contact:required')),
    phone: Yup.string()
    .matches(PHONE_REGEX, t('contact:invalidPhone')),
    comments: Yup.string()
    .max(1000, t('contact:textareaMax'))
    .required(t('contact:required'))
  })

  const sendEmail = async (values) => {
    const { email, firstName, lastName, comments, phone } = values
    try {   
      setLoading(true); 
      await feathersStore.sendContactEmail( email, firstName, lastName, comments, phone);    
      setShowSuccess(true);    
      setLoading(false);    
    }catch(err){
      setLoading(false);
      console.log('Could not send email FROM sendEmail method: ', err )
      setShowError(true);      
    };
  }

  return(
    <>
      <div>
        <h1 className="form-title">{t("contact:contact-form")}</h1>
      </div>
      <>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={{
            firstName: (feathersStore.user?.firstname !== 'default' && feathersStore.user?.firstname) || "",
            lastName: (feathersStore.user?.firstname !== 'default' && feathersStore.user?.lastname)  || "",
            phone: (feathersStore.user?.firstname !== 'default' && feathersStore.user?.phone) || "",
            email: (feathersStore.user?.firstname !== 'default' && feathersStore.user?.email) || "",
            comments: ""
          }}
          validationSchema={validationSchema}
          onSubmit = {(values, { setSubmitting, resetForm, validateForm }) => {
            setSubmitting(false);
            sendEmail(values);
            resetForm();
            validateForm();
          }}
        >
          {({errors, touched, isValid, isSubmitting}) => (
            <Form style={{marginBottom: '4em'}}>
              <div className="form">
                <div>
                  <div>
                    <label htmlFor="firstName">{t("contact:firstName")}
                      <span>*</span>
                    </label>
                  </div>
                  <Field 
                    name="firstName"
                    type="text"
                    className="form-control fieldclass"
                  />
                  {errors.firstName && touched.firstName ? (
                  <div id="error-message">{errors.firstName}</div>
                    ) : null}
                  </div>
                  <div>
                    <div>
                      <label htmlFor="lastName">{t("contact:lastName")}
                      <span>*</span></label>
                    </div>
                    <Field
                      name="lastName"
                      type="text"
                      className="form-control fieldclass"
                    />
                    {errors.lastName && touched.lastName ? (
                      <div id="error-message">{errors.lastName}</div>
                    ) : null}
                  </div>
                  <div>
                    <div>
                      <label htmlFor="email">{t("contact:email")}
                        <span>*</span>
                      </label>
                    </div>
                    <Field
                      name="email"
                      type="email"
                      className="form-control fieldclass"
                    />
                    {errors.email && touched.email ? (
                      <div id="error-message">{errors.email}</div>
                    ) : null}
                  </div>
                  <div>
                    <div>
                      <label htmlFor="phone">{t("contact:phone")}</label>
                    </div>
                    <Field
                      name="phone"
                      type="text"
                      className="form-control fieldclass"
                    />
                    {errors.phone && touched.phone ? (
                      <div id="error-message">{errors.phone}</div>
                    ) : null}
                  </div>
                  <div>
                    <label className="form-textarea" htmlFor="comments">{t("contact:text")}
                      <span>*</span>
                    </label>
                  </div>
                  <Field
                    style={{backgroundColor: "#fafafa"}}
                    as="textarea"
                    className="form-control"
                    name="comments"
                    rows="4"
                  />
                  {errors.comments && touched.comments ? (
                    <div id="error-message">{errors.comments}</div>
                  ) : null}
              </div>
                <GreenSubmitButton
                  type="submit"
                  disabled = {isSubmitting || !isValid}
                  loading={loading}
                  onClick={sendEmail}
                  className='submit-form'
                  title={t("contact:button-form")}
                  // color="#333"
                />
              </Form>
            )}
          </Formik>
      </>
      <SmallAlert 
        show={showSuccess} 
        styling="info"
        heading={t("contact:email-sent")}
        onClose={() => setShowSuccess(false)}
        message={t("contact:email-sent-message")}  />
      <SmallAlert 
        show={showError} 
        styling="danger"
        heading={t("contact:email-error")} 
        onClose={() => setShowError(false)} 
        message={t("contact:email-error-message")} />        
    </>
  )
}

export default inject('feathersStore')(observer(Contact));