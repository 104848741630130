import React, {useState, useEffect, useRef} from 'react';
import Modal from 'react-awesome-modal';
import { 
  FormControl,
  FormHelperText,
  Input, 
  Button, 
  InputAdornment,
  IconButton  
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import FacebookIcon from '@material-ui/icons/Facebook';
import GreenSubmitButton from '../buttons/greenSubmitButton';
import AppColors from '../assets/colors';
import { inject, observer } from "mobx-react";
import Utils from '../../Utils'; 
import { useTranslation } from 'react-i18next';
import FacebookLogin from 'react-facebook-login';
import { DEFAULT_EMAIL, DEFAULT_PSW } from "../assets/globalConstants";

let emailElement;
let passwordElement;

const Prompt = ({visible, onClickAway, feathersStore,
      onClickForgot,  onClickRegister, close,
      fromCart, goToAddress})=> {

  const { t, i18n } = useTranslation('common'); 

  const [email, setEmail] = useState(""); 
  const [password, setPassword] = useState("");    
  const [loading, setLoading] = useState(false);
  const [fbLoading, setFbLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); 
  const [emailError, setEmailError] = useState(false); 
  const [passwordError, setPasswordError] = useState(false);  
  const [secondTime, setSecondTime] = useState(false);
  
  const isMountedRef = useRef(null);

  useEffect(()=> {
    i18n.changeLanguage(feathersStore.language);    
  },[feathersStore.language]);
    
  useEffect(() => {
    setLoading(false);
    isMountedRef.current = true;  
    visible && emailElement.focus();
    if(feathersStore.isAuthenticated 
      && feathersStore.user?.firstname !== "default" 
      && fromCart && feathersStore.goToAddressHelper) goToAddress();
    return () => isMountedRef.current = false;
  }, [visible, feathersStore.isAuthenticated])
       
  const emailChange = event => {    
    setEmail(event.target.value);
    secondTime && emailValidation(event.target.value);   
  };

  const passwordChange = event => {    
    setPassword(event.target.value);
    passwordValidation(event.target.value);   
  };

  const emailValidation = val => {
     if (!Utils.validateEmail(val) ) {
      setEmailError(true);
    }else{
      setEmailError(false);
    }
  } 

  const passwordValidation = val => {
    if (!Utils.validatePassword(val) ) {
     setPasswordError(true);
   }else{
     setPasswordError(false);
   }
 } 

  const passwordFocus = () => { 
    setSecondTime(true);
    emailValidation(email);
  };

  const enterPressed = (ev) => {   
    if (ev.key === 'Enter') {
        passwordElement.focus();
    }
  }

  const submitEditing = async(ev) => {
    if (ev.key === 'Enter') {
      email && password && !emailError && !passwordError && await signIn();
    }
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleExit = () => {
    setSecondTime(false);
    setEmailError(false);
    setPasswordError(false);
    setEmail('');
    setPassword('');
    close();
  }

  const signIn = async() => {
    feathersStore.setGoToAddressHelper(true); 
    isMountedRef.current && setLoading(true);
    feathersStore.isAuthenticated && await feathersStore.logout();           
    feathersStore.login(email, password)
      .then(async(auth) => {                            
        await feathersStore.setObservables();  
        handleExit();               
      })
      .catch(async(error) => {
        window.alert(`Λανθασμένο login: ${error.message}`);
        await loginAsDefault();
        handleExit();
      });      
  };

  const loginAsDefault = async()=> {
    isMountedRef.current && setLoading(true);
    try{
        await feathersStore.logout();      
        feathersStore.login(DEFAULT_EMAIL, DEFAULT_PSW)
          .then(async(auth) => { 
           // history.push('/') ; 
            await feathersStore.setObservables();                                 
        })
        isMountedRef.current && setLoading(false);
    }catch{
        console.log('ERROR LOGGING OUT');
        isMountedRef.current && setLoading(false);
    }   
  } 
  
  const responseFacebook = async(response) => {
    feathersStore.setGoToAddressHelper(true); 
    isMountedRef.current && setFbLoading(true);
    feathersStore.isAuthenticated && await feathersStore.logout();
    feathersStore.facebookAuth(response.accessToken.toString())
      .then(async auth => {
        await feathersStore.setObservables();  
        handleExit();               
      })
      .catch(async(error) => {
        window.alert(`Λανθασμένο login: ${error.message}`);
        await loginAsDefault();
        handleExit();
      });  
  }

  return (
    <section>
      <Modal visible={visible} width="600" height="600" effect="fadeInUp" onClickAway={onClickAway}>
        <div className="modalContainer">
          <div className="modalTitleContainer">
            <h1>{t("common:login-registration")}</h1>
          </div>
          <div className="modalFormContainer">
          <FormControl fullWidth={true} >
            <Input
              value={email}     
              error={emailError}
              inputRef={r => {
                emailElement = r;
              }}
              onChange={emailChange}              
              onKeyPress={enterPressed}
              placeholder="E-mail"
            />
            {emailError && <FormHelperText id="component-error-text" >{t("common:emailError")}</FormHelperText>}
          </FormControl>
          <FormControl fullWidth={true}>
            <Input
              value={password}
              error={passwordError}              
              inputRef={r => {
                passwordElement = r;
              }}             
              type={showPassword ? 'text' : 'password'}
              onChange={passwordChange}
              onFocus={passwordFocus}          
              onKeyPress={submitEditing}              
              placeholder="Password" 
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
             {passwordError && 
              <FormHelperText id="component-error-text" color="secondary">{t("common:password-helper-text")}</FormHelperText>}
          </FormControl>
            <GreenSubmitButton
              onClick={signIn} 
              disabled = {!email || !password || emailError || passwordError}
              title={t("common:login")}
              loading={loading}
            />             
            <Button 
              onClick={onClickForgot}>
              <a style={{color: AppColors.secondary, fontSize: 12}}>{t("common:forgot-password")}</a>
              </Button>
          </div>
          <div className="maybe">
            <div className="OR">
              <hr className="hr"/>
            </div>
            <a style={{margin: 5}}>{t("common:or")}</a>
            <div className="OR">
              <hr className="hr"/>
            </div>
          </div>
          <div className="modalFacebookContainer">
            <FacebookLogin
              appId="1148106532315445"              
              fields="name,email,picture"          
              callback={responseFacebook}             
              icon={<FacebookIcon />}
              textButton={t("common:facebook-login")}
              cssClass="my-facebook-button-class"
            />
            
          </div>
        <div className="modalRegisterButtonContainer">
            <h4>{t("common:register")}</h4>
            <GreenSubmitButton
            title={t("common:register-button")}
            onClick={onClickRegister}
            />
        </div>
        <div className="modalExitButtonContainer">
          <Button 
            style={{color: AppColors.red, fontSize: 18}} 
            onClick={handleExit}
          >{t("common:exit")}</Button>
        </div>
        </div>
      </Modal>
    </section>
        );
}

export default inject('feathersStore')(observer(Prompt));
