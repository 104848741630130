import React, { useEffect, useState } from 'react'
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import "./Footer.css";
import { useTranslation } from 'react-i18next';
import { inject, observer } from "mobx-react";
import {Link} from 'react-router-dom';

const Footer = ({ feathersStore })=> {

  const { t, i18n } = useTranslation('footer');

  const [facebookUrl, setFacebookUrl] = useState("");
  const [instagramUrl, setInstagramUrl] = useState("");
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");
  
  useEffect(() => {
    setFacebookUrl(feathersStore.settings?.facebookUrl);
    setInstagramUrl(feathersStore.settings?.instagramUrl);
    setYoutubeUrl(feathersStore.settings?.youtubeUrl);
    setTwitterUrl(feathersStore.settings?.twitterUrl);
  },[feathersStore.settings])

  useEffect(()=> {
    i18n.changeLanguage(feathersStore.language);    
  },[feathersStore.language]);

  return (
    <>
      <div className="footer">
        <div className="footer-container">
          <div className="row-flex-center"> 
            <div className="social-media-icons">
              <h3>{t("footer:follow-me")}</h3>
              <a href={facebookUrl} target="_blank" className=""><FacebookIcon /></a>
              <a href={instagramUrl} target="_blank" className=""><InstagramIcon /></a>
              <a href={youtubeUrl} target="_blank" className=""><YouTubeIcon /></a>
              <a href={twitterUrl} target="_blank" className=""><TwitterIcon /></a>
            </div>
            <div className="rules-use">
              <Link className="privacy-text" to="/about">{t("footer:cv")}</Link>
              <Link className="privary-text" to="/contact" >{t("footer:contact-us")}</Link>
              <Link className="privacy-text" to="/privacypolicy">{t("footer:privacy-policy")}</Link>
              <Link className="privacy-text" to="/termsnconditions">{t("footer:terms-of-service")}</Link>
            </div>
          </div> 
        </div> 
        <span className="draw-line"></span>
        <div className="copyright">
          <p>POWERED BY <a href="https://appdate.gr" target="_blank">APPDATE.GR</a></p>
        </div>
      </div>
    </>
  )
}

export default inject('feathersStore')(observer(Footer));