import React, { useEffect } from 'react';
import GreenSubmitButton from '../buttons/greenSubmitButton';
import { useTranslation } from 'react-i18next';
import { inject, observer } from "mobx-react";

const LogoutComponent = ({onClickNo, deleteItem, loading, feathersStore})=> { 
  
  const { t, i18n } = useTranslation('common');

  useEffect(()=> {
    i18n.changeLanguage(feathersStore.language);
  },[feathersStore.language]);

  return (
    <div className="column-flex-center grey-background pt-1">
      <h6 id="logouttitle">{t("common:warning")}</h6>
      <p>{t("common:logout-warning")}</p>
      <div className="addNewAddressButtonsContainer">
        <GreenSubmitButton 
          title={t("common:no")}
          loading={false}
          disabled={false}
          onClick={onClickNo}
          color="secondary"
          autoFocus
        />
        <h3 style={{color: 'grey'}}>|</h3>
        <GreenSubmitButton 
          title={t("common:yes")}
          loading={loading}
          disabled={false} 
          onClick={deleteItem}
          color="primary"
        />
    </div>
  </div>
  );
}

export default inject('feathersStore')(observer(LogoutComponent));