import React, { useState } from 'react';
import { inject, observer } from "mobx-react";
import './CategoriesNavBar.css';
import { CircularProgress } from '@material-ui/core';
import { setPageStateUpdate } from '@material-ui/data-grid';
import CategoriesNavMenu from "../../cards/categoriesNavMenu";
import HomeIcon from '@material-ui/icons/Home';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CategoriesNavBar = ({feathersStore})=> {

  const { t, i18n } = useTranslation('common');

  const history = useHistory();
  const {name} = useParams();

  const resetProducts = () => {
    feathersStore.setSearchCategory({}); 
    history.push({
      pathname: "/"  
    }); 
  } 
   
  const renderCategoryItem = (node) => (   
    <div key={node.id}>
      <CategoriesNavMenu node={node}/>
    </div>
  );

  return(
    <>
      <div className="cat-navbar-container">
        <div onClick={() => resetProducts()} className="category-nav-view-item" >      
          <span className={`category-nav-text ${!feathersStore.searchCategory?.title && 'category-text-visited'}`}> 
            {/* <span>
              <HomeIcon />
            </span> */}
            <span>{t('common:home')}</span>           
          </span>     
        </div>
        {feathersStore.categories ? 
          feathersStore.categories?.map(renderCategoryItem) 
          :
          <CircularProgress 
            color="secondary" 
            style={{ margin: 6 }} 
            size={'4rem'}         
          />
        }        
      </div>      
    </>
  )


}

export default inject('feathersStore')(observer(CategoriesNavBar));