import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { inject, observer } from "mobx-react";
import {Link} from 'react-router-dom';
import CategoryPic from './categoryPic';
import ReactHtmlParser from 'react-html-parser'

const ResumeComponent = ({feathersStore}) => {

  const { t, i18n } = useTranslation('common');

  // useEffect(()=> {
  //   i18n.changeLanguage(feathersStore.language);
  // },[feathersStore.language]);

  return (
    <>
      <div className="resume-card">
        <img src={require('../img/arthub/anthia-mazaraki.jpg')} alt="Anthia Mazaraki" className='resume-img' />
        <div className='resume-text'>
          <h3>{t("common:artists-resume-name")}</h3>
          <p>{t("common:short-biography")}</p>
          <Link to="/about" className="resume-learn-more">
              <p>{t("common:learn-more")}</p>
          </Link>
        </div>
      </div>
    </>
  )
}

export default ResumeComponent;