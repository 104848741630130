import React, { useEffect } from 'react';
import Carousel from '../../carousel/Carousel';
import ResumeComponent from '../../cards/resumeComponent';
import ListProducts from '../listProducts';
import "./HomeBody.css";
import { inject, observer } from "mobx-react";
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';

const HomeBody = ({ feathersStore })=> {

  const { t, i18n } = useTranslation('offers');

  const {name} = useParams();
  const { search } = useLocation();

  let catId;

  useEffect(()=> {
    i18n.changeLanguage(feathersStore.language);
  },[feathersStore.language]);

  //Browser BackButton and Refresh usage
  useEffect(()=>{
    let payload =  {};
    if ((name || search) && feathersStore.categories) {
      searchTree(feathersStore.categories, name);
     name && Object.assign(payload, {categoryId: catId, title: name});
     search && Object.assign(payload, {searchText: search.substr(1)});
    }
    feathersStore.setSearchCategory(payload);
 },[name, search,  feathersStore.categories]);

 const searchTree = (tree, name) => {
   tree.forEach(element => {
    if(element.name === name){
      catId = element.id
    }else{
      element.childrens && searchTree(element.childrens, name)
    }
   }) 
  }

  return(
    <>
      <div className="column-flex-center home-container">
        {feathersStore?.isAuthenticated && (!name) ? (
          <>
            <div className="column-flex-center">
              <Carousel/>
            </div>
          </>)
          : null
         }
        <ListProducts />
     </div>
     <div className="resume-section">
         <ResumeComponent />
     </div>
    </>
  );
}

export default inject('feathersStore')(observer(HomeBody));