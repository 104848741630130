import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { inject, observer } from "mobx-react";
import { useTranslation } from 'react-i18next';

const RegisteredCustomer = ({onClickSignIn, feathersStore})=> {

    const useStyles = makeStyles((theme) => ({
        root: {
          '& > *': {
            margin: theme.spacing(1),
          },
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
          },
          selectEmpty: {
            marginTop: theme.spacing(2),
          },
        
          searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          inputRoot: {
            color: 'inherit',
          },
          inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('sm')]: {
              width: '21ch',
              '&:focus': {
                width: '25ch',
              },
            },
          },
      }));

    const { t, i18n } = useTranslation('checkout');

    useEffect(()=> {
      i18n.changeLanguage(feathersStore.language);    
    },[feathersStore.language]);

    return(
        <div className="btn-group" style={{marginBottom: 20}}>
            <button type="button" style={{width: 260, display: 'flex', flexDirection: 'row', alignItems: 'center'}} 
              className="btn btn-secondary dropdown-toggle"  
              aria-haspopup="true" 
              aria-expanded="false"
              onClick={onClickSignIn}>
                <h5>{t("checkout:already-customer")}</h5>
            </button>           
        </div>
    )
}

export default inject('feathersStore')(observer(RegisteredCustomer));