import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import GreenSubmitButton from '../buttons/greenSubmitButton';
import { inject, observer } from "mobx-react";
import { useTranslation } from 'react-i18next';

function getModalStyle() {
  const top = 50 ;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));


const AddressDeleteModal = ({visible, onClickNo, itemToDelete, feathersStore})=> {

  const { t, i18n } = useTranslation('common');

  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [loading, setLoading] = useState(false); 

    const deleteAddress = async () => {
      setLoading(true);  
      let clonedAddresses=[...feathersStore.user?.addresses]; 
      if(itemToDelete){
        const index = clonedAddresses.indexOf(itemToDelete);
        clonedAddresses.splice(index, 1);
      } 
      try{
        const user = await feathersStore.patchAddresses( feathersStore.user._id, [...clonedAddresses]);
        feathersStore.setUser(user);
        setLoading(false);   
        alert("Επιτυχής Διαγραφή Διεύθυνσης");
      }catch (error){
        setLoading(false);   
        alert(`Αποτυχία Ενημέρωσης:  ${error.message}`)
      }
    };

    useEffect(()=> {
      i18n.changeLanguage(feathersStore.language);    
    },[feathersStore.language]);
    
    return (     
         <Dialog
          open={visible}
          onClose={onClickNo}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          disableRestoreFocus={true}
        >
           <DialogTitle id="alert-dialog-title">{t('common:danger')}</DialogTitle>
           <DialogContent>
           <DialogContentText id="alert-dialog-description">
           {t('common:delete-address')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            <GreenSubmitButton 
              title={'common:no'}
              loading={false}
              disabled={false} 
              onClick={onClickNo}             
              autoFocus          
            />   
            <h3 style={{color: 'grey'}}>|</h3>
            <GreenSubmitButton 
              title={'common:yes'}
              loading={loading}
              disabled={false} 
              onClick={deleteAddress}  
            />              
         </DialogActions>         
        </Dialog>     
    );
}

export default inject('feathersStore')(observer(AddressDeleteModal));