import React, {useState, useEffect} from 'react';
import "./Product.css"
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import ProductCard from '../../components/cards/productCard/ProductCard';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import NavigateNext from '@material-ui/icons/NavigateNext'
import NavigateBefore from '@material-ui/icons/NavigateBefore'
import FormHelperText from '@material-ui/core/FormHelperText';
import { inject, observer } from "mobx-react";
import { useHistory } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { API_URL } from "../../components/assets/globalConstants";
import SmallAlert from "../../components/modals/smallAlert";
import { useTranslation } from 'react-i18next';

let initialCartBiggestId = 0;
let cart = [];

// const GoldRating = withStyles({
//   iconFilled: {
//     color: '#ff6d75',
//   },
//   iconHover: {
//     color: '#ff3d47',
//   },
// })(Rating);

const Product = ({ location, feathersStore })=> {

  const history = useHistory();
  const { t, i18n } = useTranslation('common');

  let paramProduct; 

  const [product, setProduct] = useState({});
  const [imageList, setImageList] = useState([]);
  const [randomProducts, setRandomProducts] = useState([]);
  const [variations, setVariations] = useState([])
  const [variation, setVariation] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [warning, setWarning] = useState(false);  
  const [purchased, setPurchased] = useState(false);
  
  //Information Modal controls
  const [cartIncreased, setCartIncreased] = useState(false);

  useEffect(()=> {
    i18n.changeLanguage(feathersStore.language);    
  },[feathersStore.language]);
  
  useEffect(() => {
    if(localStorage.getItem('fire_cart')){
      cart = JSON.parse(localStorage.getItem('fire_cart'));    
      if(cart.length > 0)
        initialCartBiggestId = +cart.map(e => e.id).sort().reverse()[0] + 1;         
    }
  }, [location?.payload]);

  useEffect(() => {    
    feathersStore.isAuthenticated && initProduct();  
  }, [location?.payload, feathersStore.isAuthenticated, 
    feathersStore?.favoritesArray, feathersStore.productUpdatedEvent]); 

  useEffect(() => {    //--Real time updates
    paramProduct && realTimeUpdates();
  }, [feathersStore.productUpdatedEvent]);
  
  useEffect(() => {    
    product && feathersStore.user?.firstname !== "default" && checkIfPurchased();
  }, [product, feathersStore.user]);  

  const initProduct = async() => {
      
    paramProduct = location?.payload;

    //USAGE: for reloads
    if(!location?.payload){ 
      const {id} = location.state;
      paramProduct = await feathersStore.getProduct(id);
    }
    setupProduct();
  }

  const setupProduct = () => {
    if(paramProduct?.hasVariation){
      setVariations(paramProduct?.variations)
      setVariation(paramProduct?.variations[0])
    }
    setImageList(getImageArr(paramProduct));
    setProduct(paramProduct);
  }

  const realTimeUpdates = async() => {
    if( feathersStore.productUpdatedEvent._id === paramProduct._id){
      paramProduct = await feathersStore.getProduct(paramProduct._id);
      setupProduct();
    }
  }

  const getImageArr = product => {
    let imageArr = [];
    if(product.hasVariation){
      imageArr = product.variations
        .map(variation => variation.images
        .map(image => Object.assign(image, {variation: variation.name}))) //Add variation name to the image
        .flat();
    }else{
      imageArr = product.images
    }
    return imageArr;
  }

  const checkIfPurchased = async() => {
    const orders = await feathersStore.ordersPerUser();
    const check = orders?.map(order => order.items).flat().find(prod => prod.product_id === product._id);
    setPurchased(check);
  }

  const onPressAddToFavorites = async () => {
    let id = '';
    id = product._id;
    if(feathersStore.favoritesArray.includes(id)){
      const index = feathersStore.favoritesArray.indexOf(id);
      if (index > -1) {
        feathersStore.favoritesArray.splice(index, 1);
      }
    }else{
      feathersStore.favoritesArray.push(id);
    }   
    if(feathersStore.wishlistId === ""){
        const data = await feathersStore.createWishlist();
        data && (feathersStore.wishlistId = data._id); 
    }else await feathersStore.updateWishlist();
  };

  useEffect(()=>{
    feathersStore.isAuthenticated && fetchProducts();
  },[feathersStore.isAuthenticated, feathersStore.productUpdatedEvent, feathersStore.productCreatedEvent]);

  const fetchProducts = async() => {
    const feathersProducts = 
    await feathersStore.products();
    setRandomProducts([...feathersProducts]); 
  }

  const getMainImage = (product)=> {
    let list = getImageArr(product);
    const mainImg = list.find(img => img.focused);
    return mainImg;
  }

  const handlePress = (product)=> {
    history.push({
      pathname: `/product/${product.name}`,
      state: {id: `${product._id}`},
      payload: product      
    }); 
  }

  const getPrice = (product)=> {      
    if(product.hasVariation){
      let priceArr = [];
      priceArr = product.variations
        .map(variation => +variation.retailPrice)
        const low = Math.min(...priceArr)
        const high = Math.max(...priceArr)
        if(low === high){
            return high.toFixed(2)
        }else{
            return low.toFixed(2) + " - " + high.toFixed(2)
        }          
    }else{
        return (+product.retailPrice).toFixed(2);
    }
  }

  const getBadges = product => {    
    if(product.hasVariation){
      let vrtion = product.variations
        .find(variation => variation.images.map(image => image.focused));
      return vrtion.badges;
    }
    return product.badges;
  }

  const getOldPrice = product => {
    if(product.hasVariation){
      let vrtion = product.variations
        .find(variation => variation.images.map(image => image.focused));
      return vrtion.oldPrice;
    }
    return product.oldPrice;
  }

  const Menu = randomProducts.map(product => {
    
    return (
      <MenuItem key={product._id}>
        <ProductCard
          key={product._id}
          stock={product.handleStock && product.stock}
          image={getMainImage(product)}
          title={product.name}
          price={getPrice(product)}
          oldPrice={getOldPrice(product)}
          onClick={()=> handlePress(product)}
          onClickCart={() => handleAddToCart(product, true)}
          hasVariation={product.hasVariation}
          onClickForward={()=> handlePress(product)}
          badges={getBadges(product)}
        />
      </MenuItem>
    );
  });

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

  const classes = useStyles();

  const renderImages = imageList.map((image, index)=>
    (<div key={image.name} style={{display: 'inline-grid'}}>
      <button style={{backgroundColor: image.focused && 'rgb(137, 170, 169)'}}  onClick={()=>selectImage(image)}>
        <img alt="" src={`${API_URL}/images/${image?.name}`}/>
      </button>
    </div>)
  );

  const renderMainImage = imageList.map((image, index)=>(   
    image.focused && <img className="hl-image" alt=""  key={image.name}
    src={`${API_URL}/images/${image?.name}`} />
  ))   
        
  const selectImage = (image)=> {
    const index = imageList.indexOf(image)
    let newList = [...imageList]
    newList.forEach(item => item.focused = false)
    newList[index].focused = true
    setImageList([...newList]) 
    setVariation(variations.find(v => v.name === image.variation))
  }
 
  const handleChange = event => {
    let selectedVariation = event.target.value;    
    setVariation(selectedVariation); 
    setQuantity(1)    
    let newList = [...imageList]         
    if(selectedVariation.image === null){
      setSelected(true)
    }else{                
      newList.forEach(item => item.focused = false)
      const index = newList.findIndex(img => img.name === selectedVariation.images[0].name)      
      newList[index].focused = true;
    } 
    setImageList([...newList]); 
  };

  const variationSelections = variations?.map((item, index) => (
      <MenuItem key={index} value={item}>{item.name}</MenuItem>
  ))

  //-------FEATURES LOGIC ----------------------------------------//
 
  const [featuresSelected, setFeaturesSelected] = useState([])
  const [featureSelectedName, setFeatureSelectedName] = useState('')
  const [error, setError] = useState(false)

  const renderFeatures = product => product.savedFeaturesTables?.map((table, index) => (
    <FormControl key={index} variant="outlined" className={classes.formControl} error={error}>
      <InputLabel id="demo-simple-select-outlined-label">{table.title}</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={featureSelectedName}
        onChange={(e)=> handleFeatures(e, table.title)}
        label={table.title}
      >
      {  table.savedFeaturesArray.map((i, index) => (
        <MenuItem disabled={i.active === false}            
          key={index}          
          value={i.name}>{i.name}</MenuItem>
      ))}            
      </Select>
      {featureSelectedName === '' && <FormHelperText style={{width: 'auto', color: '#000'}}>Κάντε μια επιλογή</FormHelperText>}
      {error && <FormHelperText>Κάντε μια επιλογή</FormHelperText>}
    </FormControl>
  ))


  const handleFeatures = (e, title)=> {
      let name = e.target.value
      let item = {
          title,
          name
      }
      const featuresSelectedClone = [...featuresSelected];
      const index = featuresSelectedClone.findIndex(val => val.title === title);
      index !== -1 ? featuresSelectedClone.splice(index, 1, item) : featuresSelectedClone.push(item);
      setFeatureSelectedName(name)
      setFeaturesSelected([...featuresSelectedClone]);
  } 

  const handleAddToCart = (prod, fromList) => { //fromList = prod is coming from the footer list and has no variations   
    let cartItem = {
      id: initialCartBiggestId,
      product_id: prod._id,
      title: prod?.name,
      image: fromList  ? prod.images?.find(img => img.focused) : imageList?.find(img => img.focused),
      price: prod.hasVariation ? variation?.retailPrice : prod?.retailPrice  ,
      quantity: fromList ? 1 : quantity,
      totalPrice: ((prod?.hasVariation ? +variation.retailPrice : +prod.retailPrice)*quantity).toFixed(2),
      variationTitle: prod?.variationTitle,
      variation: prod.hasVariation ? variation?.name : "",
      stock: prod.hasVariation ? variation?.handleStock && variation?.stock : prod?.handleStock && prod?.stock,
      extra: [...featuresSelected],
      handleStock: prod.hasVariation ? variation?.handleStock : prod?.handleStock
    }

    const cartSimilarItems = cart.filter(item => item.product_id === cartItem.product_id );
    let cartSimilarItem;  
    if(prod.hasVariation)cartSimilarItem = cartSimilarItems.find(sim => sim.variation === cartItem.variation);
    else cartSimilarItem = cartSimilarItems[0];
    if(cartSimilarItem){  
      const newQ = +cartSimilarItem.quantity + +cartItem.quantity;            
      if((prod.handleStock || variation.handleStock) && (newQ > cartItem.stock)){
        setWarning(true) ;
        return       
      }else{
        const cartIndex = cart.findIndex(item => item.id === cartSimilarItem.id);
        cartItem.quantity = newQ;
        cartItem.totalPrice = (cartItem.price * newQ).toFixed(2);
        cart.splice(cartIndex, 1, cartItem);
      }          
    }else{   
      cart.push(cartItem);
      feathersStore.setCartLength(feathersStore.cartLength + 1);      
    } 
    if(!warning){
      localStorage.setItem("fire_cart", JSON.stringify(cart));
      setCartIncreased(true);
    }
    //!fromList && !warning && resetProduct();
  }

  const setNewQuantity = (e)=> {
      let count = e.target.value;
      setQuantity(count)
  }

  const [selected, setSelected] = useState(0)
  
  const ArrowLeft = <NavigateBefore style={{cursor: 'pointer', fontWeight: 'bold', fontSize: 50, color: 'grey'}}/>
  const ArrowRight = <NavigateNext style={{cursor: 'pointer', fontWeight: 'bold', fontSize: 50, color: 'grey'}}/>;

  return(
    <>
      <div className="product-mainBody">
        <div className="product-images-container">
          <div className="product-images">
            <div className="product-main-image" style={{position: 'relative'}}>
              {renderMainImage}
              {((product?.badges || variation?.badges)?.includes('new')) &&  (
                <div className="new-label-container">
                  <p className="product-label">NEW</p>
                </div>)
              }
              {(((product?.badges || variation?.badges)?.includes('sale')) || product?.oldPrice || variation?.oldPrice) &&  (
                <div className="discount-label-container">
                  <p className="product-label">SALE</p>
                </div>)
              }
              {((product?.badges || variation?.badges)?.includes('hot')) &&  (
                <div className="hot-label-container">
                  <p className="product-label">HOT</p>
                </div>)
              }     
            </div>
            <div className="product-collection-images">
              {renderImages}        
            </div>
          </div>
          </div>
          
          <div className="product-info">
            <div className="product-title">
              <h3>{product?.name}</h3>
            </div>
            {/* <div className="productview-price">
              {feathersStore.isAuthenticated && feathersStore.user?.firstname !== "default" && 
                <AddToWishListButton 
                  onClick={onPressAddToFavorites}
                  favorite={favorite}
                />}
            </div> */}
            <div className="productview-price">              
                {(variation?.oldPrice || product?.oldPrice) &&
                  <h4  className="product-old-price">
                    <span>{((product?.hasVariation ? +variation.oldPrice : +product.oldPrice)*quantity).toFixed(2)} € </span>                   
                  </h4>
                }
                <h4>{((product?.hasVariation ? +variation.retailPrice : +product.retailPrice)*quantity).toFixed(2)} €</h4>
             
            </div>
            <div className="html-text">
                {ReactHtmlParser(`<div>${product?.htmlContent}</div>`)} 
            </div>
           
              {product?.hasVariation &&  
                <div className="productVariation">
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="native-select">{product.variationTitle}</InputLabel>
                    <Select 
                      labelId="native-select"
                      value={variation}
                      onChange={handleChange}
                      id='ns'
                      label={product.variationTitle}
                    >
                      {variations?.map((item, index) => (
                        <MenuItem key={index} value={item}>{item?.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              }

            {
              product?.hasOtherFeatures && 
              <div className="productVariation" style={{marginBottom: 20}}>
                {product?.hasVariation ? renderFeatures(variation) : renderFeatures(product)}</div> 
            }

            {product?.handleStock && !product.hasVariation &&
              <div className="productStock">
                <div>{product?.stock !==0 ? <p>{product.stock} σε απόθεμα</p> : <p style={{color: 'red'}}>Εξαντλημένο</p>}</div>
              </div>
            }

            {product?.hasVariation && !variation && 
              <div className="productStock"><p style={{color: '#000'}}>Κάντε μια επιλογή</p></div>
            }

            {product?.hasVariation && variation?.handleStock && <div className="productStock">
              {variation.stock !== 0 ? <p>{variation.stock} σε απόθεμα</p> : <p style={{color: 'red'}}>Εξαντλημένο</p>}
            </div>}

            <SmallAlert
              show={warning}
              styling="danger"
              heading={t("common:warning")}
              onClose={() => setWarning(false)}
              message={t("common:stock-warning")} />

            <div className="productAddToCart">
              <input value={quantity} onChange={setNewQuantity} type="number" min="1" style={{width: 50, paddingLeft: 5}}
                 max={product?.handleStock || variation?.handleStock ? product?.stock || variation?.stock : null} />
              <button disabled={(product?.handleStock || variation?.handleStock) && 
                ((product?.stock || variation?.stock) - quantity < 0) || (product.hasOtherFeatures && !featureSelectedName)
                || warning} 
                onClick={() => handleAddToCart(product, false)} className="btn btn-primary btn-sm" type="submit" >Προσθήκη στο καλάθι
              </button>
            </div>
          </div>
      </div>
      <div className="container">
        <h4>{t('common:view-more')}</h4>
          {feathersStore.isAuthenticated &&
            <ScrollMenu
              data={Menu}
              arrowLeft={ArrowLeft}
              arrowRight={ArrowRight}
              selected={selected}
            />
          }
      </div>
      <SmallAlert
        show={cartIncreased}
        styling="info"
        heading={t("common:updated")}
        onClose={() => setCartIncreased(false)}
        message={t("common:cart-updated")} />
      <SmallAlert
        styling="info"
        heading={t("common:updated")}
        message={t("common:wishlist-updated")} />
   </>
  )
}

export default inject('feathersStore')(observer(Product));