import React,{ useState, useEffect } from 'react';
import CategoryPic from '../cards/categoryPic';
import ReactHtmlParser from 'react-html-parser';
import { inject, observer } from "mobx-react";
import { useTranslation } from 'react-i18next';

const About = ({ feathersStore })=> {
  const { t, i18n } = useTranslation('footer');

  useEffect(()=> {
    i18n.changeLanguage(feathersStore.language);    
  },[feathersStore.language]);
    
  return(
    <div className="body">
      <h1 className='resume-title'>{t('footer:cv')}</h1>
      <div className="resume-about-section">
        { feathersStore.settings?.images &&
          <CategoryPic image={`https://feathers.287arthub.com/images/${feathersStore.settings?.images[0]}`}/>           
        }
        {feathersStore.settings?.shortDescription &&
          <div className="categoryView html-text ">
            {ReactHtmlParser(`<div>${feathersStore.settings?.shortDescription}</div>`)} 
          </div>
        }
      </div>
    </div>
  )
}

export default inject('feathersStore')(observer(About));