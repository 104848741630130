import React from 'react';


const CategoryPic = ({image})=> {
  return(
    <div 
      className="category-pic" 
      style={{backgroundImage: `url(${image})`}}
    />
  )
}

export default CategoryPic;