import React, { useEffect } from 'react';
import Modal from 'react-awesome-modal';
import { Button, CircularProgress } from '@material-ui/core';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';
import AppColors from '../assets/colors';
import { inject, observer } from "mobx-react";
import { useTranslation } from 'react-i18next';


const ConfirmExitModal = ({ loading, visible, no, yes, feathersStore })=> {
    const useStyles = makeStyles((theme) => ({
        margin: {
          margin: theme.spacing(1),
        },
      }));
      
      const theme = createMuiTheme({
        palette: {
          primary: red,
          secondary: green,
        },
      });
    const classes = useStyles();

    const { t, i18n } = useTranslation('common');

    useEffect(()=> {
      i18n.changeLanguage(feathersStore.language);    
    },[feathersStore.language]);
    
  return (
    <section>
      <Modal visible={visible} width="350" height="100" effect="fadeInUp" >
        <div className="modalContainer">
        <div className="modalTitleContainer">
        <p style={{color: AppColors.secondary}}>{t("common:logout-warning")}</p>
        </div>
        <div className="confirmationModalButtonsView">
        <Button 
        style={{color: AppColors.red, fontSize: 18}} 
        onClick={no}
        >{t("common:no")}</Button>
        <h3 style={{color: AppColors.secondary, marginTop: 10}}>|</h3>
        <Button 
          style={{color: 'rgb(137, 170, 169)', fontSize: 18}} 
          onClick={yes}
        >          
       {t("common:yes")}
        {loading && 
          <CircularProgress 
            color="secondary" 
            style={{ marginLeft: 6 }} 
            size={'1.5rem'}            
          />                  
        }
        </Button>
        </div>
        </div>
      </Modal>
    </section>
  );
}

export default inject('feathersStore')(observer(ConfirmExitModal));