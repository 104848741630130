import React from 'react';
import { useTranslation } from 'react-i18next';
import PrivacyText from './privacyText';

const PrivacyPolicy = ()=> {

    const { t, i18n } = useTranslation('common');

    return(
        <div className="body">          
            {/* <div className="pageIcon">
                <FormatListNumberedIcon style={{fontSize: 150, color: '#333'}}/>
            </div> */}
            <h1 style={{color: '#333', letterSpacing: 2, paddingTop: '15px'}}>{t("common:privacy-policy")}</h1>
            <div className="categoryView">
                <PrivacyText/>
            </div> 
        </div>
    )
}

export default PrivacyPolicy;