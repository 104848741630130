import React, {useState, useEffect} from 'react'
import ProductCard from '../cards/productCard/ProductCard';
import WishItemCartMessage from '../modals/wishItemCartMessage';
import PageTitle from './pageTitle';
import { inject, observer } from "mobx-react";
import { useHistory } from 'react-router-dom';
import * as cloneDeep from 'lodash/cloneDeep';
import SmallAlert from '../modals/smallAlert';
import { useTranslation } from 'react-i18next';

let initialCartBiggestId = 0;
let cart = [];

const WishList = ({ feathersStore })=> {

  const history = useHistory();
  const { t, i18n } = useTranslation('common');
  
  const [products, setProducts] = useState([]);  
  const [loading, setLoading] = useState(true);
  const [cartIncreased, setCartIncreased] = useState(false);
  const [favoritesUpdated, setFavoritesUpdated] = useState(false);

  useEffect(()=> {
    i18n.changeLanguage(feathersStore.language);    
  },[feathersStore.language]);

  useEffect(()=>{
    feathersStore.isAuthenticated && onLoad();
    products && setLoading(false);
  }, [feathersStore.isAuthenticated, feathersStore?.favoritesArray]);

  useEffect(() => {
    if(localStorage.getItem('fire_cart')){
      cart = JSON.parse(localStorage.getItem('fire_cart'));    
      if(cart.length > 0)
        initialCartBiggestId = +cart.map(e => e.id).sort().reverse()[0] + 1;         
    }
  }, []);


  const onLoad = async() => {    
    const feathersProducts = await feathersStore.wishlistProducts();         
    feathersProducts && setProducts([...feathersProducts]);         
  }

  const getMainImage = product => {
    let imageArr = [];
    if(product.hasVariation){
      imageArr = product.variations
        .map(variation => variation.images)
        .flat()
        .filter(image => image.focused);        
    }else{
      imageArr = product.images
      .filter(image => image.focused);       
    }  
    return imageArr && imageArr[0];
  }

  const getBadges = product => {    
    if(product.hasVariation){
      let vrtion = product.variations
        .find(variation => variation.images.map(image => image.focused));
      return vrtion.badges;
    }
    return product.badges;
  }

  const getOldPrice = product => {
    if(product.hasVariation){
      let vrtion = product.variations
        .find(variation => variation.images.map(image => image.focused));
      return vrtion.oldPrice;
    }
    return product.oldPrice;
  }

  const getPrice = (product)=> {      
    if(product.hasVariation){
      let priceArr = [];
      priceArr = product.variations
        .map(variation => +variation.retailPrice)
        const low = Math.min(...priceArr)
        const high = Math.max(...priceArr)
        if(low === high){
            return high.toFixed(2)
        }else{
            return low.toFixed(2) + " - " + high.toFixed(2)
        }          
    }else{
        return (+product.retailPrice).toFixed(2)
    }
  }

  const handlePress = (product)=> {
    history.push({
      pathname: `/product/${product.name}`,
      state: {id: `${product._id}`},
      payload: product
    }); 
  }

  const onPressDeleteFromFavorites = async (product, index) => {
    try{
      let productsClone = [...products];
      productsClone.splice(index, 1);
      setProducts([...productsClone]);
      const ind = feathersStore.favoritesArray.indexOf(product._id);    
      feathersStore.favoritesArray.splice(ind, 1);    
      await feathersStore.updateWishlist(); 
      setFavoritesUpdated(true);
    }catch(error){
      console.log(error);
    }
    
  };

  const handleAddToCart = (prod, index) => { //fromList = prod is coming from the footer list and has no variations
   
    let cartItem = {
      id: initialCartBiggestId,
      product_id: prod._id,
      title: prod?.name,
      image: prod.images?.find(img => img.focused),
      price: prod?.retailPrice,
      quantity: 1,
      totalPrice: (+prod.retailPrice).toFixed(2),
      variationTitle: null,
      variation: null,
      stock: prod?.handleStock && prod?.stock,
      extra: [],
      handleStock: prod?.handleStock
  }

  const cartSimilarItem = cart.find(item => item.product_id === cartItem.product_id );  
  if(cartSimilarItem){  
    const newQ = +cartSimilarItem.quantity + 1;            
    if(prod.handleStock && (newQ > cartItem.stock)){
      setWarning(index);   
      return;
    }else{
      const cartIndex = cart.findIndex(item => item.id === cartSimilarItem.id);
      cartItem.quantity = newQ;
      cartItem.totalPrice = (+cartItem.price * newQ).toFixed(2);
      cart.splice(cartIndex, 1, cartItem);
    }          
  }else{   
    cart.push(cartItem);
    feathersStore.setCartLength(feathersStore.cartLength + 1);    
  } 
    if(!prod.warning){
      localStorage.setItem("fire_cart", JSON.stringify(cart));
      setCartIncreased(true); 
    }   
  }

  const setWarning = (index) => {
    let productsClone = [...products];
    let productClone = cloneDeep(products[index]);
    Object.assign(productClone, {warning: true});
    productsClone.splice(index, 1, productClone);
    setProducts([...productsClone]);
  }

  const removeWarning = (index) => {
    let productsClone = [...products];
    let productClone = cloneDeep(products[index]);
    Object.assign(productClone, {warning: false});
    productsClone.splice(index, 1, productClone);
    setProducts([...productsClone]);
  }

  const renderProducts = products.map( (product, index) => (
  
    <li key={product._id} style={{display: 'block', float: 'left'}}>
      <ProductCard
        key={product._id}
        stock={product.handleStock && product.stock}
        image={getMainImage(product)}
        title={product.name}
        price={getPrice(product)}
        oldPrice={getOldPrice(product)}
        onClick={()=> handlePress(product)}
        onClickCart={()=> handleAddToCart(product, index)}
        hasVariation={product.hasVariation}
        onClickForward={()=> handlePress(product)}
        warning={product.warning}
        onCloseWarning={() => removeWarning(index)}
        favorite={true}
        onPressAddToFavorites={() => onPressDeleteFromFavorites(product, index)}
        badges={getBadges(product)}
      />
    </li>
    ) 
  );

  return(
    <>
    <div className="container">
      <div className="cart-body">
      <PageTitle title={t('common:favorite')}/>
      {/*}
      <WishItemDeleteModal
        visible={visible}
        onClickNo={}
        deleteItem={}
        loading={loading}
      />
  */}
      <WishItemCartMessage
     //   visible={message}
     //   onClick={closeMessage}
      />
      <div className="table-container">
        {products.length === 0 ? <h5>{t('common:no-favorites-yet')}</h5> 
        :  
        <ul style={{paddingLeft: 60, maxWidth: '130vh'}}>
        {renderProducts}
        </ul>    }
      </div>
    </div>    
  </div>
  <SmallAlert 
    show={cartIncreased} 
    styling="info"
    heading={t("common:updated")} 
    onClose={() => setCartIncreased(false)} 
    message={t("common:cart-updated")} />
  <SmallAlert 
    show={favoritesUpdated} 
    styling="info"
    heading={t("common:updated")} 
    onClose={() => setFavoritesUpdated(false)} 
    message={t("common:wishlist-updated")} />
  </>
  )
}

export default inject('feathersStore')(observer(WishList));