import React, { useState, useEffect } from 'react';
import Modal from 'react-awesome-modal';
import GreenSubmitButton from '../buttons/greenSubmitButton';
import { inject, observer } from "mobx-react";
import VerificationErrorModal from './verificationErrorModal';
import { useTranslation } from 'react-i18next';
import { DEFAULT_EMAIL, DEFAULT_PSW } from "../assets/globalConstants";
import SmallAlert from "../modals/smallAlert";

let box0;
let box1;
let box2;
let box3;

const VerificationCode = ({visible, onClickAway, onClickCloseCode, payload,  feathersStore})=> {

  const { t, i18n } = useTranslation('common');

  const [value0, setValue0] = useState('');
  const [value1, setValue1] = useState('');
  const [value2, setValue2] = useState('');
  const [value3, setValue3] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorOnCreate, setErrorOnCreate] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(()=> {
    i18n.changeLanguage(feathersStore.language);    
  },[feathersStore.language]);

  const handleTextChange0 = (e)=> {
    e.preventDefault();
    setValue0('');
    const value = e.target.value;
    if(value && !isNaN(value)){
      setValue0(value);  
      box1.focus(); 
    }     
  }

  const handleTextChange1 = (e)=> {
    setValue1('');
    const value = e.target.value;
    if(value && !isNaN(value)){      
      setValue1(value);  
      box2.focus(); 
    }     
   }

  const handleTextChange2 = (e)=> {
    setValue2('');
    const value = e.target.value;
    if(value && !isNaN(value)){
      setValue2(value);  
      box3.focus(); 
    }    
  }

  const handleTextChange3 = (e)=> {
    setValue3('');
    const value = e.target.value;
    if(value && !isNaN(value)){
      setValue3(value); 
    }
  }

  const exitError = () => {
    setError(false);
    onClickAway();
  }

  const resend = async() => {
    const { applicationId, firstName, lastName, email } = payload
    await feathersStore.sendAgain(applicationId, firstName, lastName, email)
    setError(false);
  }

  const submit = () => { 
    const pin = value0 + value1 + value2 + value3;
    const { applicationId, firstName, lastName, email, password, phone } = payload  
    setLoading(true);
    feathersStore.newestEmail(applicationId)
        .then(async newestEmail =>{         
              const { random4digit } = newestEmail[0];                  
              if(+pin === random4digit){
                try{                
                  const user = await feathersStore.createUser(firstName, lastName, email, password, phone);
                  user && feathersStore.login(email, password)
                  .then(async(auth) => {         
                    await feathersStore.setObservables();
                    await feathersStore.updateApplicationToProcessed(applicationId)
                    setLoading(false); 
                    onClickCloseCode();
                  })
                  .catch(error => console.log('error while authenticating: ', error));                
                }catch(error){
                  console.log('error while creating user: ', error) 
                  feathersStore.login(DEFAULT_EMAIL, DEFAULT_PSW)
                  .then(async(auth) => {         
                      await feathersStore.setObservables();
                      setErrorMessage(error.message);                         
                      setErrorOnCreate(true);
                      onClickCloseCode();
                  })
                  setLoading(false); 
                }
              }else{
                setLoading(false); 
                setValue0('');
                setValue1('');
                setValue2('');
                setValue3('');
                setError(true);
                return;            
              }
        });
  }

 
  return (
    <section>
      <Modal visible={visible} width="600" height="600" effect="fadeInRight" onClickAway={onClickAway}>
        <div className="modalContainer">
          <div className="modalTitleContainer">           
            <h1>{t("common:verification-code")}</h1>                               
          </div>
          <p style={{color: 'grey'}}>{t("common:verification-info")}<br/> {payload?.email}</p>
          <div className="modalVerificationCodeContainer">
            <input className="modalVerificationCodeBox" 
              type="text" 
              maxLength="1" 
              onChange={handleTextChange0}                     
              ref= {(element) => box0 = element}              
              required
              style={{backgroundColor: value0.length > 0 && '#333'}}  
            />
            <input className="modalVerificationCodeBox" 
              type="text" 
              maxLength="1" 
              onChange={handleTextChange1}                      
              ref= {(element) => box1 = element}             
              required
              style={{backgroundColor: value1.length > 0 && '#333'}}   
            />
            <input className="modalVerificationCodeBox" 
              type="text" 
              maxLength="1" 
              onChange={handleTextChange2}                       
              ref= {(element) => box2 = element}              
              required
              style={{backgroundColor: value2.length > 0 && '#333'}}   
            />
            <input className="modalVerificationCodeBox" 
              type="text" 
              maxLength="1" 
              onChange={handleTextChange3}                        
              ref= {(element) => box3 = element}              
              required
              style={{backgroundColor: value3.length > 0 && '#333'}}   
            />
          </div>
          <div className="modalFacebookContainer">
            <GreenSubmitButton
              title={t("common:enter-code")}
              loading={loading} 
              onClick={submit}
              disabled={value0.length < 1 || value1.length < 1 || value2.length < 1 || value3.length < 1 }
            >              
            </GreenSubmitButton>
          </div>
        </div>
      </Modal> 
      <VerificationErrorModal
        visible={error}
        exit={exitError}
        resend={resend}
      >
      </VerificationErrorModal>
      <SmallAlert
        show={errorOnCreate} 
        styling="danger"
        heading="error while creating user" 
        onClose={()=>setErrorOnCreate(false)} 
        message={errorMessage}
     />
    </section>
   
  );
}

export default inject('feathersStore')(observer(VerificationCode));
