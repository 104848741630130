import React, { useEffect } from 'react'
import PersonIcon from '@material-ui/icons/Person';
import {Link} from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const AccountButton = (feathersStore)=> {

    const { t, i18n } = useTranslation('account');

    useEffect(()=> {
        i18n.changeLanguage(feathersStore.language);    
      }, [feathersStore.language]);

    return(
        <Link to="/account" style={{textDecoration: 'none'}}>
            <button className="nav-button-SignIn">
                <PersonIcon style={{fontSize: 30, color: '#F2ECFF'}}/>
                <span>{t("account:myAccount")}</span>
            </button>
        </Link>
    )
}

export default AccountButton;