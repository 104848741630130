import React, { useState, useEffect } from 'react';
import { inject, observer } from "mobx-react";
import {Link} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from '@material-ui/core/Menu';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import { useHistory } from 'react-router-dom';
import '../screenComponents/categoriesBar/CategoriesNavBar.css'

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: 350,
    flexGrow: 1,
    maxWidth: 450, 
    marginRight: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(0),
   // marginRight: theme.spacing(0),
    width: theme.spacing(8),
   // backgroundColor: Colors.primary,
    color: "#fff"
  },
  menuItem: {
    fontSize: theme.spacing(2),
  }
}));

const CategoriesMenu = ({node, feathersStore})=> {

  const classes = useStyles(); 
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null); 
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePress = item => () => {       
    const payload =  {    
      categoryId: item.id,  
      title: item.name, 
      searchText: ""
    }; 
    feathersStore.setSearchCategory(payload);    
    history.push({
      pathname: `/Home/${item.name}`      
    });
    handleClose();
  }

  const renderTree = (nodes) => (
    <TreeItem 
      key={nodes.id} 
      nodeId={nodes.id.toString()} 
      label={nodes.name} 
      onLabelClick={handlePress(nodes)}
      collapseIcon={<ExpandMoreIcon />}
      expandIcon={<ChevronRightIcon />}      
    >
      {Array.isArray(nodes.childrens) ? nodes.childrens.map((node) => renderTree(node)) : null}
    </TreeItem>
  );
 
  return(
    <>
      <div className="dropdown">      
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <TreeView
            className={classes.root}            
            defaultExpanded={[]}            
          >
            {node.childrens ? 
              node.childrens?.map(renderTree) 
              :
              null
            }
          </TreeView>
        </Menu>
      </div> 
      <div 
        className="category-nav-view-item"
      ><span className='category-nav-text'>
          <span 
            className={`${node.id === feathersStore.searchCategory?.categoryId && 'category-text-visited'}`}
            onClick={handlePress(node)} >{node.name}
          </span>
          {node.childrens.length > 0 && <span 
            onClick={node.childrens.length > 0 ? handleClick : null}>
              <ArrowDropDownIcon />
            </span>}
        </span>
      </div>
   </>
  )
}
export default inject('feathersStore')(observer(CategoriesMenu));
