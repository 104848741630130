import React, {useEffect} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Home from './home/Home'
import ScrollToTop from '../components/screenComponents/scrollToTop'
import HomeBody from '../components/screenComponents/homeBody/HomeBody';
import AdministrativeDashboard from './administrativeDashboard';
import { inject, observer } from "mobx-react";


const Main = ({feathersStore})=> {

    const defaultEmail = "defaultUser@gmail.com";
  const defaultPassword = ")j~nKj/,N}N6,8&cVVV#G!=F*y";

  useEffect(() => {
    connectToServer();
    createPaymentMethodLS();    
  }, []);

  const connectToServer = async() => {
    await feathersStore.connect();    
    if(!feathersStore.isAuthenticated)feathersStore.login(defaultEmail, defaultPassword)
    .then(async(auth) => {         
          await feathersStore.setObservables();                          
    })
    .catch(error => window.alert(`Λανθασμένο login: ${error.message}`));   
  }

  const createPaymentMethodLS = () => {
    !localStorage.getItem('Payment_Method') &&      
      localStorage.setItem('Payment_Method',JSON.stringify({
          name: 'Μετρητά κατά την παράδοση',
          index: 0
      }))
  }

  return(
    <Router>
      <Switch>
          <Route path="/dashboard" exact component={AdministrativeDashboard}/>
      <ScrollToTop>
          <Home>
              <Route component={({match})=> 
              <div>
                  <Route path="/" exact component={HomeBody}/>
              </div>
          }/>
          </Home>
      </ScrollToTop>
      </Switch>
    </Router>
  )
}

export default inject('feathersStore')(observer(Main));