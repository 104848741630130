import React, { useState, useEffect } from 'react'
import SignInButton from '../../buttons/signInButton'
import CartButton from '../../buttons/cartButton/CartButton';
import AccountButton from '../../buttons/accountButton'
import { inject, observer } from "mobx-react";
import {Link} from 'react-router-dom';
import './TopBarBootstrap.css';
import { useTranslation } from 'react-i18next';
import CategoriesNavBar from '../categoriesBar/CategoriesNavBar'

const TopBar = ({onClickSignIn, feathersStore})=> {

  const { t, i18n } = useTranslation('common');
 
  const resetProducts = () => {
    feathersStore.setSearchCategory({});
  }

  const toggleLanguage = lang => {
    feathersStore.setLanguage(lang);
  }

  const [settingsPhone, setSettingsPhone] = useState("");
    
  useEffect(() => {
    const phoneNumber = feathersStore.settings?.phoneNumbers &&
    feathersStore.settings?.phoneNumbers.find(p => p.numberEnabled).phoneNumber;
    setSettingsPhone(phoneNumber);   
  },[feathersStore.settings?.phoneNumbers])
   
  return(
    <div className="top-bar-nav"  role="navigation">
      <nav className="navbar py-0 px-0 navbar-expand-lg navbar-dark bg-transparent w-100 custom-navi">
        <Link to="/" style={{color: '#f2ecff'}}onClick={() => resetProducts()} className="navbar-brand nav-nav-left Artist-Name">{t('common:artists-name')}</Link>
        <button
          id="btn-menu"
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target=".multi-collapse"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
          >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse multi-collapse navbar-collapse w-100" id="navbarNavAltMarkup">
          <div className="navbar-nav nav">
            <div className="nav-nav-right nav-item">
              {feathersStore.isAuthenticated && feathersStore.user?.firstname === "default" &&
                <div className="nav-SignIn">
                  <SignInButton onClickSignIn={onClickSignIn}/>
              </div> }
            </div>
            <CategoriesNavBar />
            <div className="nav-nav-right nav-item">
              {feathersStore.isAuthenticated && feathersStore.user?.firstname !== "default" &&
                <div className="nav-SignIn">
                  <AccountButton/>
                </div>}
            </div>
            <div className="nav-cart-button-container nav-item ">
              <CartButton/>
            </div>
            {/* <Link to="/checkout" className="checkout-button-container nav-item ">
              TAMEIO
            </Link> */}
            <div className="nav-item dropdown mt-1 dropleft language-flags">
              <a className="nav-link dropdown-toggle language-styling text-white"
                href="#" id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
                {feathersStore.language === "en" ?
                  <img src={require('../../img/languages/language-1.png')}  alt="..."/> :
                  <img src={require('../../img/languages/language-2.png')}  alt="..."/>
                }
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a className="dropdown-item" href="javascript:void(0);" onClick={() => toggleLanguage("el")}>
                  <span style={{alignSelf: 'flex-start'}}>
                    <img src={require('../../img/languages/language-2.png')}  alt="..."/>
                  </span>
                  Ελληνικά</a>
                <a className="dropdown-item" href="javascript:void(0);" onClick={() => toggleLanguage("en")} >
                  <span>
                    <img src={require('../../img/languages/language-1.png')}  alt="..."/>
                  </span>
                  English</a>
              </div>
            </div> 
          </div>
        </div>
      </nav>
    </div>
  )
}

export default inject('feathersStore')(observer(TopBar));